<template>
    <div v-if="allEnquriesLoaded == true">
       <nav-bar :enquiry_id="enquiry_id"/>
       <div class="enquiry_screen">
           <div class="enquiry_screen__left">
                <client-references :ref_type="ref_type" :enquiry="getEnquiry" @update-date="updateDate" @update-id="updateId"/>
                <enquiry-set-status :options="filter_enquiries" :enquiry="getEnquiry" @update-enquiry-status="updateStatus"/>
                <client-details :enquiry="getEnquiry" @update-client-details="updateClientDetails" :ref_type="this.ref_type"/>
                <div class="mob__hide">
                    <action-button :title="enquiryButtonTitle" @buttonClicked="saveEnquiry"/>
                </div>
           </div>
           <div class="enquiry_screen__mid">
                <service-details :enquiry="getEnquiry"
                    @update-service-details="updateServiceDetails"
                    @update-service-length="updateServiceLength"/>
                <enquiry-notes :enquiry="getEnquiry" @update-notes="updateNotes" :title="`NOTES`"/>
                <div class="mob__show">
                    <action-button :title="enquiryButtonTitle" @buttonClicked="saveEnquiry"/>
                </div>
           </div>
           <div class="enquiry_screen__right">
                <project-link :enquiry="getEnquiry" :status="this.enquiry.project.started" @button-clicked="projectStarted"/>
                <fee-letter-list :enquiry="getEnquiry" :buttonTitle="this.isFeeLetter" @open-fee-letter="openFeeLetter" :isEnableToCreateFeeLetter="isEnableToCreateFeeLetter" :fee_letter_ref="hasFeeLetterReference"/>
           </div>
       </div>
       <overlay-fee-letter v-if="this.overlayFeeLetterActivate == true"
                           :templates="templates" 
                           :fee_letter="enquiry.enquiry_fee_letter.template" 
                           :template_name="enquiry.enquiry_fee_letter.template_name" 
                           :fee_letter_date="enquiry.enquiry_fee_letter.date" 
                           :fee_letter_ref="enquiry.enquiry_fee_letter.ref" 
                           :fee_letter_price="enquiry.enquiry_fee_letter.price" 
                           :enquiry_id="enquiry_id" 
                           :enquiry="getEnquiry" 
                           :can_download="this.isReadyToDownload"
                           :saved="this.isFeeLetterSaved"
                           :allEnquriesLoaded="allEnquriesLoaded"
                           @update-fee-letter="updateFeeLetterDetails" 
                           @update-date="updateFeeLetterDate" 
                           @update-price="updateFeeLetterPrice" 
                           @save-fee-letter="saveFeeLetter" 
                           @update-status="updateTemplate" 
                           @update-fee-leter-reference="updateFeeLetterReference"
                           />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from "@/components/general/NavBar.vue";
import ClientReferences from "@/components/enquiry/ClientReferences.vue";
import ClientDetails from "@/components/enquiry/ClientDetails.vue";
import EnquirySetStatus from "@/components/enquiry/EnquirySetStatus.vue";
import ActionButton from "@/components/general/ActionButton.vue";
import ServiceDetails from "@/components/enquiry/ServiceDetails.vue";
import ProjectLink from "@/components/enquiry/ProjectLink.vue";
import EnquiryNotes from "@/components/enquiry/EnquiryNotes.vue";
import OverlayFeeLetter from "@/components/enquiry/OverlayFeeLetter.vue";
import FeeLetterList from "@/components/enquiry/FeeLetterList.vue";
export default {
    name: 'EnquiryScreen',
    components: {
        NavBar, ClientReferences, EnquirySetStatus, ClientDetails, ActionButton, ServiceDetails, ProjectLink, EnquiryNotes, FeeLetterList, OverlayFeeLetter
    },
    data(){
        return {
            editing: false,
            ref_type: "Enquiry",
            filter_enquiries: ['queued', 'working', 'agreed', 'cancelled'],
            enquiry: {
                enquiry_id: '',
                enquiry_date : "",
                    client_details : {
                    name : "",
                    address_line_1 : "",
                    address_line_2 : "",
                    postcode : "",
                    email : "",
                    mobile : "",
                    landline : ""
                    },
                service_details : "",
                service_length : "",
                status : "",
                notes : [],
                enquiry_fee_letter : {
                    price : "", 
                    template : [ 
                    ],
                    template_name : "",
                    date : "",
                    ref: "",
                    saved: false
                },
                project: {
                    started: false,
                    status: "",
                    agreed_surveyor: "",
                    agreed_surveyor_name: "",
                    agreed_surveyor_letter: {},
                    adjoining_owners: [],
                    party_wall_notices: [],
                    supporting_documents: [],
                    soc: "",
                    award: ""
                }
            }
        }
    },
    props: ['enquiry_id'],
    computed: {
        getEnquiry(){
            var neededEnquiryNo = this.enquiry_id;
            for (let i = 0; i < this.enquiries.length; i++){
                if (this.enquiries[i].details.enquiry_id == neededEnquiryNo) {
                    return this.enquiries[i].details
                }
            }
            return null
        },
        isFeeLetter(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter.ref != '' && enquiry.enquiry_fee_letter != ''){
                    return `Open Fee Letter`
                } else {
                    return `Create Fee Letter`
                }
            } else {
                return `Create Fee Letter`
            }
        },
        isFeeLetterSaved(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter.saved){
                    return enquiry.enquiry_fee_letter.saved
                } else {
                    return this.enquiry.enquiry_fee_letter.saved
                }
            } else {
                return this.enquiry.enquiry_fee_letter.saved
            }
        },
        hasFeeLetterReference(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter.ref != ''){
                    return enquiry.enquiry_fee_letter.ref
                } else {
                    return this.enquiry.enquiry_fee_letter.ref
                }
            } else {
                return this.enquiry.enquiry_fee_letter.ref
            }
        },   
        isEnableToCreateFeeLetter(){
            if(this.getEnquiry != undefined){
                return true
            } else {
                return false
            }
        },
        isReadyToDownload(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter == '' || enquiry.enquiry_fee_letter.ref == ''){
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        allEnquriesLoaded(){
            //checking whether enquiries have loaded lets the components wait for the data before loading
            //especially needed for if the page gets reloaded, otherwise the component loads before app.js can fetch the data
            this.getSavedFeeLetter()

            return (this.enquiriesLoaded ? true : false)
        },
        enquiryButtonTitle(){
            if(this.enquiry.enquiry_id == ''){
                return `Save Enquiry`
            } else {
                return `Update Enquiry`
            }
        },
    ...mapState([
          'enquiries', 'enquiriesList', 'enquiriesLoaded', 'enquiriesLoaded',
          'projectsLoaded', 'overlayFeeLetterActivate', 'templates'
          ])
  },
  methods: {
        updateClientDetails(details){
            // will need some validation before making the records, maybe do it in each component
            this.enquiry.client_details = details
        },
        updateStatus(status){
            console.log('existing updateStatus');
            this.enquiry.status = status
        },
        updateTemplate(template){
            // console.log('updateTemplate fired');
            // console.log(template);
            this.enquiry.enquiry_fee_letter.template_name = template[0]
            this.enquiry.enquiry_fee_letter.saved = template[1]
        },
        updateDate(date){
            this.enquiry.enquiry_date = date
            // this.editing = true
        },
        updateNotes(notes){
            this.enquiry.notes = notes
            // this.editing = true
        },
        updateId(id){
            if(this.enquiry.enquiry_id == ''){
                this.enquiry.enquiry_id = id
            }
        },
        updateServiceDetails(option){
            this.enquiry.service_details = option
            // console.log(this.enquiry.service_details);
            // this.editing = true
        },
        updateServiceLength(option){
            this.enquiry.service_length = option
            // console.log(this.enquiry.service_length);
            // this.editing = true
        },
        updateFeeLetterDetails(template){
            console.log('updateFeeLetterDetails fired');
            var enquiry = this.getEnquiry

            var preppedTemplate = [];
            for (var k = 0; k < template.details.length; k++){

                var raw = template.details[k].page

                for (var i = 0; i < raw.length; i++){
                    raw[i].content = raw[i].content.replaceAll("{{name}}", enquiry.client_details.name)
                    raw[i].content = raw[i].content.replaceAll("{{address_line_1}}", enquiry.client_details.address_line_1)
                    raw[i].content = raw[i].content.replaceAll("{{address_line_2}}", enquiry.client_details.address_line_2)
                    raw[i].content = raw[i].content.replaceAll("{{postcode}}", enquiry.client_details.postcode)
                    raw[i].content = raw[i].content.replaceAll("{{price}}", this.enquiry.enquiry_fee_letter.price)
                }
                var pageToAdd = {'page': raw} 

                preppedTemplate.push(pageToAdd)
            }
            this.enquiry.enquiry_fee_letter.template = preppedTemplate
            // this.editing = true
                        
        },
        updateFeeLetterDate(date){
            this.enquiry.enquiry_fee_letter.date = date
        },
        updateFeeLetterPrice(price){
            console.log('updateFeeLetterPrice FIRED');
            this.enquiry.enquiry_fee_letter.price = price
        },
        updateFeeLetterReference(ref){
            this.enquiry.enquiry_fee_letter.ref = ref
        },
        getSavedTemplate(){
            var enquiry = this.getEnquiry
            if(enquiry != null && enquiry != undefined){
                if(enquiry.enquiry_fee_letter.template_name != ''){
                    this.enquiry.enquiry_fee_letter.template_name = enquiry.enquiry_fee_letter.template_name
                    this.enquiry.enquiry_fee_letter.template = enquiry.enquiry_fee_letter.template
                }
            }
        },
        getSavedFeeLetter(){
            console.log('getSavedFeeLetter FIRED');
            var enquiry = this.getEnquiry
            if(enquiry != null && enquiry != undefined){
                if(enquiry.enquiry_fee_letter.saved != false){
                    this.enquiry.enquiry_fee_letter = enquiry.enquiry_fee_letter
                }
            }
        },
        saveFeeLetter(){
            console.log('saveFeeLetter FIRED, should set fee letter in the record to true');
            //will need to set the fee letter data to saved
            this.enquiry.enquiry_fee_letter.saved = true
            this.saveEnquiry()
        },
        openFeeLetter(){
            console.log('openFeeLetter called');
        },
        projectStarted(){
            console.log('projectStarted should be firing');
            console.log('this.enquiry.project.started');
            console.log(this.enquiry.project.started);
            if(this.enquiry.project.started == false){
                this.enquiry.project.started = true
                console.log(this.enquiry.project.started);
                this.saveEnquiry()
            }
        },
        saveEnquiry(){
            //this.enquiry is the one we want to create or update
            if(this.enquiry.enquiry_id == ''){
                console.log('THIS IS A NEW ENQUIRY AND NEEDS TO BE CREATED');
                var getEnquiriesList = this.enquiriesList;
                var enquiriesListInOrder = getEnquiriesList.slice().sort((a,b)=>a-b)
                var highestId = enquiriesListInOrder[enquiriesListInOrder.length - 1];
                var newEnquiryNumber = Number(highestId) + 1;
                    enquiriesListInOrder.push(newEnquiryNumber.toString())
                var list = enquiriesListInOrder
                var newKey = newEnquiryNumber;
                var details = this.enquiry
                if(details.enquiry_id == ''){
                    details.enquiry_id = newKey;
                }
                var bundle = [newKey, list, details]
                var newEnquiryRoute = "/enquiry-screen/" + newKey
                this.$store.dispatch('saveToServer', bundle)
                this.$router.push(newEnquiryRoute)
                this.updateId()
                this.editing = false

            } else {
                console.log('ENQUIRY EXISTS ALREADY AND NEEDS TO BE UPDATED');
                var details = this.enquiry
                var existingKey = this.enquiry_id
                    existingKey = existingKey.toString()
                var bundle = [existingKey, details]
                console.log(bundle);
                this.$store.dispatch('updateToServer', bundle)
                this.editing = false
            }
        },
        hasProjectStarted(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.project.started == true){
                    this.enquiry.project.started = true
                }
            }
        },
  },
  updated(){
    this.hasProjectStarted()
    this.getSavedTemplate()
    }
}
</script>

<style lang="scss" scoped>
.enquiry_screen {
    width: 100%;
    height: calc(100vh - 132px - 44px);
    padding: 22px 0px;
    background-color: #EFEFEF;
    @media all and (max-width: 1024px) {
        height: 1248px;
    }
}
.enquiry_screen__left {
    float: left;
    width: calc(30% - 88px);
    padding: 0px 44px;
    height: 100%;
    @media all and (max-width: 1024px) {
        width: calc(100% - 44px);
        padding: 0px 22px;
        padding-bottom: 30px;
        height: 540px;
    }
}

.enquiry_screen__mid {
    float: left;
    width: 40%;
    height: 100%;
    @media all and (max-width: 1024px) {
        width: calc(100% - 44px);
        padding: 0px 22px;
        height: 700px;
    }
}

.enquiry_screen__right {
    float: left;
    width: 30%;
    height: 100%;
    @media all and (max-width: 1024px) {
        display: none;
    }
}

.action_button {
    margin-top: 10px;
    width: calc(100% - 48px);
    text-align: center
}

.mob__hide {
    @media all and (max-width: 1024px) {
        display: none;
    }
}
.mob__show {
    display: none;
    @media all and (max-width: 1024px) {
        display: block;
    }
}
</style>