<template>
    <div class="service_details">
        <div class="service_details__label">
            Service Details
        </div>
        <service-details-radio :option1="`Building Owner`" :option2="`Adjoining Owner`" :status="serviceDetails"
        @update-service-detail-option="updateServiceDetails"/>
        <div class="service_details__pt10"></div>
        <service-details-radio :option1="`Full`" :option2="`Part`" :status="serviceLength"
        @update-service-detail-option="updateServiceLength"/>
        <!-- <span>{{ rerunner }}</span> -->
    </div>
</template>

<script>
import ServiceDetailsRadio from "@/components/enquiry/ServiceDetailsRadio.vue";
export default {
    name: 'ServiceDetails',
    props: ['enquiry'],
    data(){
        return {
            serviceDetails: '',
            serviceLength: ''
        }
    },
    components: {
        ServiceDetailsRadio
    },
    computed: {
        rerunner(){
            this.setServiceDetailsRadio()
            this.setServiceLengthRadio()
            return null
        }
    },
    methods: {
        setServiceDetailsRadio(){
            if(this.enquiry){
                this.serviceDetails = this.enquiry.service_details
                this.$emit('update-service-details', this.serviceDetails)
            }
        },
        setServiceLengthRadio(){
            if(this.enquiry){
                this.serviceLength = this.enquiry.service_length
                this.$emit('update-service-length', this.serviceLength)
            }
        },
        updateServiceDetails(option){
            this.serviceDetails = option
            this.$emit('update-service-details', this.serviceDetails)
        },
        updateServiceLength(option){
            this.serviceLength = option
            this.$emit('update-service-length', this.serviceLength)
        }
    },
    created(){
        this.setServiceDetailsRadio()
        this.setServiceLengthRadio()
    },
    updated(){
    }
}
</script>

<style lang="scss" scoped>

.service_details__label {
    font-weight: 900;
    text-transform: uppercase;
    color: #9C9FA0;
}

.service_details__pt10 {
    padding-top: 10px;
}
</style>
