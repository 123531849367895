<template>
    <div>
        <div class="set_status__label">PROJECT STATUS</div>
        <form action="" class="set_status">
            <select id="set_status__options" name="set_status__options" class="set_status__options" v-model="selected"
            @change="$emit('update-status', selected)">
                <option v-for="(option, i) in options" :key="i" :value="option">
                    {{ option }}
                </option>>
            </select>
        </form>
    </div>
</template>

<script>
export default {
    name: 'EnquirySetStatus',
    props: ['options', 'enquiry'],
    data(){
        return {
            selected: this.options[0]
        }
    },
    computed: {
        rerunner(){
            this.setFilterTerm()
            return null
        }
    },
    methods: {
        setFilterTerm(){
            if(this.enquiry){
                 this.selected = this.enquiry.project.status
                } else {
            }
            this.$emit('update-project-status', this.selected)
        }
    },
    created(){
        this.setFilterTerm()
    }
}
</script>

<style lang="scss" scoped>
// RESETS
*,
*::before,
*::after {
  box-sizing: border-box;
}
select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  outline: none;
}
select::-ms-expand {
  display: none;
}
// RESETS END
.set_status {
    display: inline-block;
    width: 100%;
    height: 40px;
    border-radius: 50px;
    background-color: #fff;
    background-image: url('@/assets/icon_dropdown_chev.svg');
    background-repeat: no-repeat;
    background-position: 85% center;
}
.set_status__options {
    border: 0px solid transparent;
    background-color: transparent;
    font-size: 16px;
    text-transform: uppercase;
    color: #2BB179;
    text-align: center;
    height: 40px;
    font-weight: 900;
    padding-left: 20px;
    width: calc(100% - 20px);
}

.set_status__label {
    font-weight: 900;
    color: #9C9FA0;
    padding-bottom: 10px;
    text-transform: uppercase;

}

</style>