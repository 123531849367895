import Vue from 'vue'
import VueRouter from 'vue-router'
import EnquiriesList from '../views/EnquiriesList.vue'
import EnquiryScreen from '../views/EnquiryScreen.vue'
import ProjectScreen from '../views/ProjectScreen.vue'
import ProjectsList from '../views/ProjectsList.vue'
import TheDashboard from '../views/TheDashboard.vue'
import AccountSettings from '../views/AccountSettings.vue'
import LoginScreen from '../views/LoginScreen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/enquiries-list',
    name: 'enquiries list',
    component: EnquiriesList
  },
  {
    path: '/enquiry-screen',
    name: 'enquiry screen',
    component: EnquiryScreen
  },
  {
    path: '/project-screen',
    name: 'project screen',
    component: ProjectScreen
  },
  {
    path: '/enquiry-screen/:enquiry_id',
    name: 'enquiry screen existing',
    component: EnquiryScreen,
    props: true
  },
  {
    path: '/project-screen/:enquiry_id',
    name: 'project screen existing',
    component: ProjectScreen,
    props: true
  },
  {
    path: '/projects-list',
    name: 'projects list',
    component: ProjectsList
  },
  {
    path: '/account-settings',
    name: 'account settings',
    component: AccountSettings
  },
  {
    path: '/login',
    name: 'LoginScreen',
    component: LoginScreen,
    props: true,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: TheDashboard
  },
  {
    path: '/',
    redirect: {name: 'LoginScreen'}
  },
  {
    path: '*',
    redirect: {name: 'LoginScreen'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('user')

  if (to.name == 'LoginScreen' && user || to.name == 'Register' && user) {
    next({ name: 'dashboard' }) 
  } else if (to.name !== 'LoginScreen' && to.name !== 'Register' && !user) {
    next({ name: 'LoginScreen' }) 
  } else {
    next()
  }
})

export default router
