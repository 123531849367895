<template>
    <div class="letter_preview"  v-if="this.feeLetterLoaded">
        <div class="letter_preview__title" >PREVIEW </div>
        <div class="letter_preview__holder">
            <div class="letter_preview__canvas" v-for="(page, index) in this.feeLetterContent">
                <!-- <div class="letter_preview__header" v-if="index == feeLetterContent.length - 1"> -->
                <div class="letter_preview__header" v-if="index == 0">
                    <img src="@/assets/admin/header.jpg" height="97" alt="header" :class="{inactive: headerFooterVisible == false}">
                </div>
                <div class="letter_preview__stamps"  v-if="index == 0">
                    <div class="letter_preview__date">{{ feeDateFormatted }}</div>
                    <div class="letter_preview__reference">Ref: PW/{{ feeLetterReference }}</div>
                </div>
                <div class="letter_preview__body_pad"></div>
                <div class="letter_preview__recipient_address" v-if="index == 0">
                    <span>{{ enquiry.client_details.name }}</span><br>
                    <span>{{ enquiry.client_details.address_line_1 }}</span><br>
                    <span>{{ enquiry.client_details.address_line_2 }}</span><br>
                    <span class="letter_preview__recipient_address____postcode">{{ enquiry.client_details.postcode }}</span>
                </div>
                <div class="letter_preview__body_pad"></div>
                <div class="letter_preview__body_pad"></div>
                
                <div class="letter_preview__salutation" v-if="index == 0">
                    <div v-for="(item, index) in page.page" :key="index">
                        <textarea class="editable_copy"  v-if="item.type == 'p' && item.location == 'salutation'" v-model="item.content" type="text" @input="resizeTextarea"></textarea>
                    </div>
                </div>
                <div class="letter_preview__subject" v-if="index == 0">
                    <div v-for="(item, index) in page.page" :key="index">
                        <textarea class="subject editable_copy"  v-if="item.type == 'p' && item.location == 'subject'" 
                        v-model="item.content" type="text" @input="resizeTextarea">
                        </textarea>
                    </div>
                </div>

                <div class="letter_preview__top_pad"  v-if="index != 0"></div>
    
                <div class="letter_preview__body">
                    <div v-for="(item, index) in page.page" :key="index">
                        <textarea class="editable_copy"  
                            v-if="item.type == 'p' && item.location == 'body'"
                                v-model="item.content" type="text" @input="resizeTextarea">
                        </textarea>
                    <div v-if="item.type == 'li'">
                        <div class="bullet"></div>
                        <textarea class="editable_copy list-inline"  
                            v-model="item.content" type="text" @input="resizeTextarea">
                        </textarea>
                    </div>

                    <div v-if="item.type == 'li_ol'">
                        <div class="bullet_number">{{ item.position }}.</div>
                        <textarea class="editable_copy list-inline"  
                            v-model="item.content" type="text" @input="resizeTextarea">
                        </textarea>
                    </div>

                    <div v-if="item.type == 'pad'" class="letter_preview__body_pad"></div>

                    </div>
                    <!-- Only add the signature to the last page -->
                    <div v-if="index == feeLetterContent.length - 1">
                        <div class="letter_preview__signature_graphic">
                            <img src="@/assets/signature.jpg" height="43" alt="signature" width="139">
                        </div>
                        <div class="letter_preview__signature_printed">Preston P Neunie</div>
                        <div class="letter_preview__credentials">MBA BSc (Hons) MCIOB MCMI MAPM MFPWS</div>
                        <div class="letter_preview__contact_info">M: 07956 527 294 | E: preston@neunieassociates.co.uk</div>
                        <div class="letter_preview__contact_info"></div>
                </div>
                </div>
                <div class="letter_preview__footer">
                    <img src="@/assets/admin/footer.jpg" height="93.5" alt="footer" :class="{inactive: headerFooterVisible == false}">
                </div>
                <!-- <div class="letter_preview__page_number">Page {{ index + 1 }}</div> -->
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'FeeLetterPreview',
    props: ['enquiry', 'fee_letter', 'fee_letter_date', 'fee_letter_ref', 'showHeaderFooter'],
    components: {},
    data(){
        return {
            feeLetterContent: [],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'],
            pw_ref: ''
        }
    },
    computed: {
        feeLetterLoaded(){
            if(this.fee_letter){
                return true
            } else {
                return false
            }
        },
        headerFooterVisible(){
            if(this.showHeaderFooter){
                return true
            } else {
                return false
            }
        },
        feeDateFormatted(){
            var retrievedDate = new Date(this.fee_letter_date)
            var month = retrievedDate.getMonth()
            var date = retrievedDate.getDate()
            var year = retrievedDate.getFullYear()
            var readable = date + ' ' + this.months[month] + ' ' +year
            return readable
        },
        feeLetterReference(){
            return this.fee_letter_ref
        }
    },
    methods: {
        createLetterReference(){
            if(this.enquiry.enquiry_fee_letter.ref == '' || this.enquiry.enquiry_fee_letter.ref == null){
                var address = this.enquiry.client_details.address_line_1.split(" ")
                var posteCodeEnd = this.enquiry.client_details.postcode.slice(-3)
                var foo = [];
                var reference = ''
                foo.push(address[0])
                for (var i = 1; i < address.length; i++){
                    foo.push(address[i].substring(0,3))
                }
                for (var i = 0; i < foo.length-1; i++){
                    reference = reference + foo[i]
                }
                reference = reference + posteCodeEnd;
                reference = reference.toLocaleUpperCase()
                this.pw_ref = reference
            } else {
                var reference = this.enquiry.enquiry_fee_letter.ref 
            }
            this.setFeeLetterContent()
            this.$emit('update-fee-letter-reference', reference)
        },
        setFeeLetterContent(){
            this.feeLetterContent = this.fee_letter;
        },
        resizeTextarea(e){
            e.target.style.height = '20px' //min height reset
            if(e.target.scrollHeight != e.target.clientHeight){
                // console.log(e.target.scrollHeight);
                e.target.style.height = e.target.scrollHeight + 'px'
            }
        },
        setTextareas(){
            //THIS FUNCTION REGULATES THE LINE SPACING WHEN THE USER MAKES LINES BIGGER OR SMALLER
            var textareas = document.getElementsByTagName('textarea')
            for (var i = 0; i < textareas.length; i++){
                textareas[i].style.height = '20px' //min height reset
                if(textareas[i].scrollHeight != textareas[i].clientHeight){
                    textareas[i].style.height = textareas[i].scrollHeight + 'px';
                }
            }
        },
        toggleHeaderFooter(){
            if(this.showHeaderFooter == true){
                this.showHeaderFooter = false
            } else {
                this.showHeaderFooter = true
            }
        }
    },
    mounted(){
        this.createLetterReference()
    },
    updated(){
        this.setTextareas()
        this.createLetterReference()
    }
}

</script>

<style lang="scss" scoped>
$font-stack: 'Montserrat', sans-serif;
p {
    padding-bottom: 15px;   
}
.editable_copy {
    font-size: 14px;
    font-family: $font-stack;
    font-weight: 400;
    border: none;
    width: 100%;
    height: auto;
    resize: none;
    position: relative;
    overflow: hidden;
}

.editable_copy:hover {
    outline: dashed 1px #1A86EA;
    outline-offset: -1px;
}

.list-inline {
    display: inline-block;
    width: calc(100% - 45px);
    height: 30px;
}

li {
    line-height: 100%;
}

.bullet {
    position: relative;
    top: 0px;
    width: 6px;
    height: 6px;
    margin: 10px 14px 14px 20px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
}

.bullet_number {
    position: relative;
    top: 5px;
    width: 10px;
    height: 16px;
    margin-right: 14px;
    margin-left: 14px;
    display: inline-block;
    vertical-align: top;
}
.subject {
    font-weight: 900;
    padding-bottom: 8px;
}

.letter_preview__title {
    position: relative;
    // margin-top: 80px;
    margin-top: 5px;
    font-weight: 900;
    color: #9C9FA0;
    padding-bottom: 10px;
    width: 20.9cm;
}
.letter_preview__canvas {
    font-size: 14px;
    position: relative;
    // shave 0.1 off the height and width of the canvas so the PDF generator doesn't create an extra blank page
    padding: 0.4cm 1.4cm 0cm 1.5cm ;
    margin-bottom: 0.5cm;
    height: 28.7cm;
    width: 18cm;
    background-color: #fff;
}

.letter_preview__page_number {
    bottom: -30px;
    text-align: center;
    left: 0;
    position: absolute;
    height: 20px;
    width: 100%;
}

// LAYOUT
.letter_preview__header {
    width: 100%;
    height: 96px;
    background-size: cover;
    background-repeat: no-repeat;
}
.letter_preview__date,
.letter_preview__reference {
    width: 50%;
    display: inline-block;
    font-family: $font-stack;
}
.letter_preview__reference {
    margin-top: 5px;
    text-align: right;
}
.letter_preview__stamps {
    width: 100%;
    height: 30px;
}

.letter_preview__recipient_address {
    font-family: $font-stack;
    width: 100%;
    padding: 15px 0;
    text-transform: capitalize
}
.letter_preview__recipient_address____postcode {
    text-transform: uppercase;
}

.letter_preview__signature_printed {
    font-family: $font-stack;
    // margin-top: 0.25cm;
}

.letter_preview__credentials {
    font-family: $font-stack;
    color: #9C9FA0;
    font-weight: 700;
}

.letter_preview__contact_info {
    font-family: $font-stack;
}

.letter_preview__top_pad {
    height: 1.5cm;
    width: 100%;
}

.letter_preview__body_pad {
    width: 100%;
    height: 0.4cm;
}

.letter_preview__footer {
    bottom: 10px;
    position: absolute;
    width: calc(100% - 3cm);
    height: 94px;
    // background-image: url('../assets/admin/footer.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.letter_preview__footer img.inactive, .letter_preview__header img.inactive{
    display:none
}
</style>