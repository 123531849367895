<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseDB from "@/services/firebase.js"
import { mapState } from 'vuex';
// import axios from 'axios'
export default {
  data(){
    return {
      getUserUID: '',
      userProjectsIndex: [],
      userEnquiriesIndex: [],
      userTemplatesIndex: [],
      unsubscribe : function(){console.log('unsubscribe called');},
      unsubscribeProjects : function(){console.log('unsubscribeProjects called');},
      unsubscribeEnquiriesList : function(){console.log('unsubscribeEnquiriesList called');},
      unsubscribeEnquiries : function(){console.log('unsubscribeEnquiries called');},
      unsubscribeTemplatesList : function(){console.log('unsubscribeTemplatesList called');},
      unsubscribeTemplates : function(){console.log('unsubscribeTemplates called');}
    }
  },
  computed: {
    ...mapState([
          'enquiries', 
          'projects',
          'uid',
          'reloadRequired',
          'enquiriesLoaded',
          'projectsLoaded',
          'templatesLoaded',
          ])
  },
  reloadData(){
      if(this.reloadRequired == true){
        this.runSetup()
        //set reloadRequired to false
        return false
      } else {
        return true
      }
    },
  methods: {
      runSetup(){
      console.log('runSetup FIRED');
      firebaseDB.collection("users")

      this.getUserUID = localStorage.getItem('user');

      //LOAD USER DATA FIRST
      var getUserData
      // console.log('this there a uid?');
      // console.log(this.getUserUID);
      this.$store.dispatch('setUID', this.getUserUID);
      
      this.unsubscribe = firebaseDB.collection("users").doc(this.getUserUID)
                                    .onSnapshot(doc => {
                                      // var getUserRecord = doc.data();
                                      // console.log(getUserRecord);
                                    
                                    //create a pullTimeStamp to be stored locally
                                    var curr = new Date;
                                    let utcDate = curr.getUTCDate(); 
                                    let utcMonth = curr.getUTCMonth();
                                    let utcYear = curr.getUTCFullYear();
                                    let utcHours = curr.getUTCHours();
                                    let utcMinutes = curr.getUTCMinutes();
                                    let utcSeconds = curr.getUTCSeconds();
                                    let utcCreated = new Date(Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds));

                                    var offset = curr.getTimezoneOffset()
                                    var offsetMilliseconds;
                                    switch (offset){
                                        case -60:
                                            offsetMilliseconds = 60 * 60 * 1000
                                            break;
                                        case 0:
                                            offsetMilliseconds = 0
                                            break;
                                    }

                                    this.$store.dispatch('setPullTimeStamp', utcCreated.getTime() + offsetMilliseconds);
                                    })

      },
      getEnquiriesAndProjects(){
        //GET USER PROJECTS
      this.unsubscribeProjectsList = firebaseDB.collection("users")
                                               .doc(this.getUserUID)
                                               .collection("projects")
                                               .doc('all')
                                               .onSnapshot(doc => {
                                                  var userProjects = doc.data();
                                                  this.userProjectsIndex = JSON.parse(userProjects.list)
       })
      var myProjects = []
      this.unsubscribeProjects = firebaseDB.collection("users")
                                           .doc(this.getUserUID)
                                           .collection('projects')
                                           .onSnapshot(doc => {
                                                       doc.forEach((doc) => {
                                                            for (var i = 0; i < this.userProjectsIndex.length; i++){
                                                                if (doc.data().key ==  this.userProjectsIndex[i]){
                                                                  let myProject = {details:'',key:''};
                                                                  let docContents = doc.data();
                                                                  myProject.details = JSON.parse(docContents.details)
                                                                  myProject.key = JSON.parse(docContents.key)
                                                                  myProjects.push(myProject)
                                                                }
                                                            }
                                                          })
                                                          if(this.projectsLoaded == false || this.reloadRequired == true){
                                                            this.$store.dispatch('setProjects', myProjects)
                                                            // this.projectsLoaded = true
                                                            this.$store.dispatch('setProjectsLoaded')
                                                          }
      })
      //GET USER ENQUIRIES
      this.unsubscribeEnquiriesList = firebaseDB.collection("users")
                                               .doc(this.getUserUID)
                                               .collection("enquiries")
                                               .doc('all')
                                               .onSnapshot(doc => {
                                                  var userEnquiries = doc.data();
                                                  this.userEnquiriesIndex = JSON.parse(userEnquiries.list)
                                                  if(this.enquiriesLoaded == false || this.reloadRequired == true){
                                                      this.$store.dispatch('setEnquiriesList', this.userEnquiriesIndex)
                                                  }
       })
      
      this.unsubscribeEnquiries = firebaseDB.collection("users")
                                           .doc(this.getUserUID)
                                           .collection('enquiries')
                                           .onSnapshot(doc => {
                                            var myEnquiries = []
                                            var myEnquiriesOrder = []
                                                       doc.forEach((doc) => {
                                                            for (var i = 0; i < this.userEnquiriesIndex.length; i++){
                                                                if (doc.data().key ==  this.userEnquiriesIndex[i]){
                                                                  let myEnquiry = {details:'',key:''};
                                                                  let docContents = doc.data();
                                                                  myEnquiry.details = JSON.parse(docContents.details)
                                                                  myEnquiry.key = JSON.parse(docContents.key)
                                                                  myEnquiries.push(myEnquiry)
                                                                }
                                                            }
                                                          })
                                                          for (let i = 0; i < myEnquiries.length+1; i++) {
                                                            let target = i

                                                            for (var k = 0; k < myEnquiries.length; k++){
                                                              if(target == myEnquiries[k].key){
                                                                myEnquiriesOrder.push(myEnquiries[k])
                                                              }
                                                            }
                                                          }
                                                          if(this.enquiriesLoaded == false || this.reloadRequired == true){
                                                            this.$store.dispatch('setEnquiries', myEnquiriesOrder)
                                                            // this.enquiriesLoaded = true
                                                            this.$store.dispatch('setEnquiriesLoaded')
                                                          }
      })
      // GET USER TEMPLATES
      this.unsubscribeTemplatesList = firebaseDB.collection("users")
                                               .doc(this.getUserUID)
                                               .collection("templates")
                                               .doc('all')
                                               .onSnapshot(doc => {
                                                  var userTemplates = doc.data();
                                                  this.userTemplatesIndex = JSON.parse(userTemplates.list)
                                                  if(this.templatesLoaded == false || this.reloadRequired == true){
                                                      this.$store.dispatch('setTemplatesList', this.userTemplatesIndex)
                                                  }
       })

      this.unsubscribeTemplates = firebaseDB.collection("users")
                                           .doc(this.getUserUID)
                                           .collection('templates')
                                           .onSnapshot(doc => {
                                            var myTemplates = []
                                            var myTemplatesOrder = []
                                                       doc.forEach((doc) => {
                                                            for (var i = 0; i < this.userEnquiriesIndex.length; i++){
                                                                if (doc.data().key ==  this.userEnquiriesIndex[i]){
                                                                  let myTemplate = {details:'',key:''};
                                                                  let docContents = doc.data();
                                                                  myTemplate.details = JSON.parse(docContents.details)
                                                                  myTemplate.key = JSON.parse(docContents.key)
                                                                  myTemplates.push(myTemplate)
                                                                }
                                                            }
                                                          })
                                                          for (let i = 0; i < myTemplates.length+1; i++) {
                                                            let target = i

                                                            for (var k = 0; k < myTemplates.length; k++){
                                                              if(target == myTemplates[k].key){
                                                                myTemplatesOrder.push(myTemplates[k])
                                                              }
                                                            }
                                                          }
                                                          if(this.templatesLoaded == false || this.reloadRequired == true){
                                                            this.$store.dispatch('setTemplates', myTemplatesOrder)
                                                            // this.enquiriesLoaded = true
                                                            this.$store.dispatch('setTemplatesLoaded')
                                                          }
      })
      },
      setUserState(user){
      if (user) {
        //  console.log('user is logged in');
        //  console.log(user);
         this.runSetup()
         this.getEnquiriesAndProjects()
        //  this.requestFullscreenWindow()
      } else {
        this.unsubscribe()
        this.unsubscribeProjects()
        this.unsubscribeEnquiriesList()
        this.unsubscribeEnquiries()
        this.unsubscribeTemplatesList()
        this.unsubscribeTemplates()
        // console.log('user is NOT logged in');
      }
    },
  },
  created() {
      firebase.auth().onAuthStateChanged(user => this.setUserState(user));
  },
  
}
</script>
<style lang="scss">
@import "@/scss/_core.scss";
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
