<template>
   <form action="" id="search__bar" @submit.prevent="">
            <input type="text" class="search__bar____input" v-model="searchTerm" placeholder="Search" @keyup="setSearchTerm">
            <div class="search__button">
                <div v-if="!searchActive" class="search__magnify_icon"></div>
                <div v-else class="search__clear_icon" @click="resetSearch"></div>
            </div>
        </form>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'SearchInput',
    props: {
        details: Array
    },
    data(){
        return {
            list: [],
            searchTerm: ''
        }
    },
    computed: {
        searchActive(){
            return (this.searchTerm == '') ? false : true
        },
        ...mapState(['enquiries', 'projects'])
        
    },
    methods: {
        resetSearch(){
            this.searchTerm = '',
            this.$emit('setSearchTerm', this.searchTerm)
        },
        setSearchTerm(){
            this.$emit('setSearchTerm', this.searchTerm)
        }
    }
    
}
</script>

<style lang="scss" scoped>

#search__bar{
    position: relative;
    border-radius: 50px;
    height: 40px;
    width: calc(50% - 20px);
    padding:0 15% 0 5%;
    background-color: #fff;
    display: inline-block;
    @media all and (max-width: 1124px) {
        width: 160px;
    }
    @media all and (max-width: 461px) {
        width: 30%
    }
}

.search__bar____input {
    border: 0px solid transparent;
    height: 38px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;;
    color: #2BB179;
    text-transform: uppercase;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2BB179;
opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #2BB179;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #2BB179;
}

.search__button {
    top: 0px;
    right: 6%;
    position: absolute;
    width: 40px;
    height: 40px;
}

.search__magnify_icon {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    background-image: url("@/assets/icon_search.svg");
    background-size: contain;
}
.search__clear_icon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 14px;
    height: 14px;
    background-image: url("@/assets/icon_search_cancel.svg");
    background-size: contain;
}
.search__clear_icon:hover {
    cursor: pointer;
}
</style>