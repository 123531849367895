<template>
    <div class="price">
        <div class="price__box" :class="{price__hide : template_name != 1}">
            <div class="price__label">PRICE</div>
            <input class="price__input" type="text" v-model="price">
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'PriceBox',
    props: ['enquiry', 'fee_letter_price', 'saved', 'template_name'
        ],
    data(){
        return {
            price: '950',
        }
    },
    computed: {
        ...mapState([
          'enquiries' 
          ])
    },
    methods: {
        getPrice(){
            if(this.saved == true){
                this.price = 'Inline Edit Only' //PRICE TEXTBOX CANNOT AFFECT ALREADY SAVED CONTENT AS NO {{PRICE}} WILL PERSIST
            }
            this.$emit('update-price', this.price)
        },
        updatePrice(){
            this.$emit('update-price', this.price)
        }
    },
    created(){
        this.getPrice()
    },
    updated(){
        this.updatePrice()
    }
}
</script>

<style lang="scss" scoped>
    .price {
        width: 100%;
        height: 90px;
        font-weight: 500;
        color: #9C9FA0;
    }
    .price__box {
        display: inline-block;
        width: 100%;
        height: 100%;
        // transition: 0.1s;
    }
    .price__label {
        font-weight: 900;
        padding-bottom: 10px;
        text-transform: uppercase;
    }
    .price__input {
        text-align: center;
        // font-family: 'Avenir';
        font-family: 'Montserrat', sans-serif;
        width: 80%;
        width: calc(95% - 10px);
        padding:0 5% 0 10px;
        border: 0px solid transparent;
        height: 40px;
        background-color: #fff;
        border-radius: 50px;
        font-size: 16px;
        color: #000;
        font-weight: 700;
    }

    .price__hide {
        opacity: 0;
    }
</style>