<template>
    <div>
       <nav-bar/>
       <div class="not_ready">
           Account Settings goes here <br><br> 
           <div class="logout" @click="logout">Logout</div>
       </div>
    </div>
</template>

<script>
import NavBar from "@/components/general/NavBar.vue";
import firebase from 'firebase/app';
import 'firebase/auth';
export default {
    components: {
        NavBar
    },
    methods: {
        logout(){
            firebase.auth().signOut().then(
                ()=> {
                this.$store.dispatch('logUserOut')
                this.$store.dispatch('clearState')
                this.$router.push("LoginScreen")

              }, error => {
                  this.error = error.message;
              }

            )
        },
    }
}
</script>

<style lang="scss" scoped>
.not_ready {
    font-size: 2em;
    text-align: center;
    height: calc(100vh - 132px - 6em);
    padding-top: 5em;
}
.logout {
    padding: 0.5em 2em;
    display: inline-block;
    background-color: #8833FF;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
</style>