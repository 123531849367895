<template>
    <div class="project_link">
        <div class="project_link__label">Project Link</div>
        <div class="feeletter_list__buttons____start_project">
            <action-button :title="buttonTitle" :status="projectStatus" v-if="this.projectStatus == 'disabled'"/>
            
            <action-button :title="buttonTitle" :status="projectStatus" v-if="this.projectStatus == 'enabled'" @buttonClicked="startProject"/>
        </div>
    </div>
</template>

<script>
import ActionButton from "@/components/general/ActionButton.vue";
export default {
    name: 'ProjectLink',
    props: ['enquiry', 'status'],
    components: {
        ActionButton
    },
    computed: {
        projectStatus(){
            if(this.enquiry != null && this.enquiry != undefined){
                if(this.enquiry.status == 'agreed'){
                    return 'enabled'
                } else {
                    return 'disabled'
                }
            } else {
                return 'disabled'
            }
        },
        buttonTitle(){
            if(this.enquiry != null && this.enquiry != undefined){
                if(this.enquiry.project.started == true){
                    return 'Go To Project'
                } else {
                    return 'Start Project'
                }
            } else {
                return 'Start Project'
            }
        },
        projectStarted(){
            if(this.enquiry != null && this.enquiry != undefined){
                if(this.enquiry.project.started == true){
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    },
    methods: {
        startProject(){
            console.log('Project start button clicked');
            this.$emit('button-clicked')
            this.$router.push({name: "project screen existing", params: {enquiry_id: this.enquiry.enquiry_id}})
        }
    }
}

</script>

<style lang="scss" scoped>
.project_link__label {
    font-weight: 900;
    text-transform: uppercase;
    color: #9C9FA0;
    padding-bottom: 10px;
}
.project_link {
    padding: 0px 44px 60px 44px;
}
</style>