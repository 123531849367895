<template>
    <div class="feeletter_list">
            <div class="feeletter_list__label">Fee Letter</div>
            <div class="feeletter_list__viewer">
                <div class="feeletter_list__item" v-if="enquiry.enquiry_fee_letter.saved == true">
                    <div class="feeletter_list__item____icon">
                        &nbsp;
                    </div>
                    <div class="feeletter_list__item____reference">
                        PW/{{ fee_letter_ref }}
                    </div>
                </div>
                <div class="feeletter_list__item" v-if="enquiry.enquiry_fee_letter.saved == false">
                    No letters generated
                </div>
            </div>
            <div class="feeletter_list__buttons">
                <div class="feeletter_list__buttons____create_fee">
                    <action-button :title="buttonTitle" @buttonClicked="openFeeLetter" :class="{disabled: isEnableToCreateFeeLetter ==false}"/>
                </div>
            </div>
    </div>
</template>

<script>
import ActionButton from "@/components/general/ActionButton.vue";
export default {
    name: 'FeeLetterList',
    props: ['buttonTitle', 'isEnableToCreateFeeLetter', 'fee_letter_ref', 'enquiry'],
    components: {
        ActionButton
    },
    computed: {
        projectStatus(){
            return 'disabled'
        }
    },
    methods: {
        openFeeLetter(){
            this.$store.dispatch('setOverlayFeeLetterActivate')
        }
    }
    
}
</script>

<style lang="scss" scoped>

.feeletter_list {
    padding: 0px 44px
}
.feeletter_list__label {
    margin-top: 70px;
    font-weight: 900;
    text-transform: uppercase;
    color: #9C9FA0;
}

.feeletter_list__viewer {
    margin-top: 10px;
    width: 100%;
    height: 300px;
    background-color: #fff;
    border-radius: 20px;
}
.feeletter_list__buttons {
    margin-top: 10px;
    height: 42px;
    width: 100%
}
.feeletter_list__buttons____create_fee{
    height: 42px;
    width: 100%;
}

.feeletter_list__buttons____start_project{
    margin-top: 8px;
    height: 42px;
    width: 100%;
}

.feeletter_list__item {
    position: relative;
    text-align: center;
    line-height: 100px;
    height: 100px;
    top: calc(50% - 50px);
    width: calc(100% - 44px);
    padding: 0 22px;
}

.feeletter_list__item____icon {
    display: inline-block;
    // line-height: 100px;
    height: 100px;
    width: 100px;
    background-image: url('@/assets/icon_pdf.svg');
    background-repeat: no-repeat;
    background-position: center;
    
}

.feeletter_list__item____reference {
    display: inline-block;
    line-height: 100px;
    
}

.action_button {
    margin-top: 10px;
    width: calc(100% - 48px);
    text-align: center
}
</style>