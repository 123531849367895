<template>
    <div>
      <div class="login_panel">
         <div class='loginForm'>
           <div class="login_title"></div>
             <form @submit.prevent="login">
            <input class='login' type='email' v-model='email' required placeholder='email address'
                   :class="{loginForm__error____highlight : error.code == 'invalid-email'}"
                    @change='clearForm'/><br>
            <input class='login' type='password' v-model='password' required placeholder='Password' 
                   :class="{loginForm__error____highlight : error.code == 'auth/wrong-password'}"
                   @change='clearForm'/><br>
           <div class="loginForm__error">
                <div v-if="error.code == 'auth/wrong-password'">
                    <span class="loginForm__error____icon"></span><span>Incorrect password, try again.</span>
                </div>
                <div v-if="error.code == 'auth/user-not-found'">
                    <span class="loginForm__error____icon"></span><span>No account is associated with that email address</span>
                </div>
                <div v-if="error.code == 'auth/tpp-many-requestd'">
                    <span class="loginForm__error____icon"></span><span>Account temporarily disabled. Try resetting your password.</span>
                </div>
                <div v-if="error.code == 'invalid-email'">
                    <span class="loginForm__error____icon"></span><span>That email address doesn't look quite right</span>
                </div>
            </div>

            <input class='login button highlight' type='submit' value='Log In' name='log-in' />
            </form>

            <!-- <div class="forgottenLink">
                <p @click="showOverlayForgottenPW">Forgotten password?</p>
            </div>
            <div class="loginForm_separator"></div>
            <div class="createAccount" @click="showOverlayCreateAccount">
                Create New Account
            </div> -->
        </div>
      </div>
      <overlay-create-account/>
      <overlay-forgotten-password/>
    </div>
</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';
import OverlayCreateAccount from '@/components/account/OverlayCreateAccount';
import OverlayForgottenPassword from '@/components/account/OverlayForgottenPassword';

export default {
  name: 'LoginScreen',
  data(){
      return {
          email: '',
          password: '',
          error: {
              "code" : '',
              "message" : ''
          }
      }
  },
  components: {
      OverlayCreateAccount, OverlayForgottenPassword
      },
  computed: {
  },
  methods: {
      login(){
          //clear any previous error messages
          this.clearForm()

          const info = {
              email: this.email,
              password: this.password
          }
        
          //validate email
          if (this.validateEmail(info.email) == true){
              firebase.auth()
              .signInWithEmailAndPassword(info.email, info.password)
              .then(
                  (UserCredential)=> {
                    this.$store.dispatch('logUserIn', UserCredential.user.uid)
    
                    //now push to dashboard
                    this.$router.push("dashboard")
    
                  }, error => {
                      this.error.message = error.message;
                      this.error.code = error.code;
                      console.log(error);
                  }
              )
              .catch((error) => {
                    console.log('within catch block');
                    console.log(error);
                });
          } else {
              this.error.code = 'invalid-email'
          }
           
      },
      showOverlayCreateAccount(){
        //   this.$store.dispatch('setOverlayToTrue')
        //   this.$store.dispatch('setCreateAccountToTrue')
      },
       showOverlayForgottenPW(){
        //   this.$store.dispatch('setOverlayToTrue')
        //   this.$store.dispatch('setForgottenPWToTrue')
      },
      validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
      },
      clearForm(){
          this.error.code = "";
          this.error.message = "";
      }
      
  }
}
</script>
<style scoped lang="scss">
// @import "@/scss/_variables.scss";

input.highlight {
    // font-family: 'Avenir';
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    background-color: #8833FF;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}
input[type=password] {
    border-radius: 30px;
    // border: 2px solid transparent;
}

input.highlight:hover {
    background-color: #1A86EA;
    cursor: pointer;
}

input[type=submit]:hover {
    cursor: pointer;
}
.loginForm {
    position: absolute;
    width: 100%;
    background: linear-gradient(113.2deg, #2C6C93 0%, #B8FCDC 100.43%);
    text-align: center;
    padding-top: 80px;
    height: inherit;
    @media all and (max-height: 650px) {
        padding-top: 0px;
    }
}

.loginForm__error {
    height: 32px;
    margin-top: 5px;
    color: red;
    font-weight: 400;
}

.loginForm__error____icon {
    display: inline-block;
    height: 22px;
    width: 19px;
    margin-right: 5px;
    // background-image: url(../assets/img/icon_error_warning.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 6px;
}

input[type=password].loginForm__error____highlight,
input[type=email].loginForm__error____highlight {
    border: 2px solid red;

}

.login_panel {
    height: calc(100% - 40px);
    width: 100%;
    position: absolute;
    @media all and (max-height: 650px) {
        height: 100%;
    }
}

.login {
    width: 300px;
    height: 40px;
    margin-top: 20px;
    border-radius: 30px;
    border: none;
    text-align: center;
    font-size: 1em;
    box-sizing: border-box;
    border: 2px solid transparent;
}
.login.button {
        margin-bottom: 0.5em;
    }
.login_title {
    width: 170px;
    height: 134px;
    margin: 0 auto;
    background-image: url('@/assets/harmony_logo.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.loginForm_separator {
    margin: 2em auto;
    width: 300px;
    height: 2px;
    background-color: #fff;
}

.forgottenLink {
    text-align: center;
    font-weight: 700;
    color: #fff
}

.forgottenLink:hover {
    cursor: pointer;
}

.createAccount {
    display: inline-block;
    background-color: #B8FCDC;
    color: #498E8C;
    font-weight: 700;
    margin-top: 0.5em;
    padding: 0.8em 2em;
     border-radius: 30px;
    border: none;
}

.createAccount:hover {
    cursor: pointer;
}
</style>