import { render, staticRenderFns } from "./IssueDatePicker.vue?vue&type=template&id=2067f880&scoped=true&"
import script from "./IssueDatePicker.vue?vue&type=script&lang=js&"
export * from "./IssueDatePicker.vue?vue&type=script&lang=js&"
import style0 from "./IssueDatePicker.vue?vue&type=style&index=0&id=2067f880&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2067f880",
  null
  
)

export default component.exports