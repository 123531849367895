<template>
    <div>
       <nav-bar/>
       <client-list :details="enquiries" :type="`projects`" :options="filter_projects" :height="client_list_height" :scrollheight="client_list_scrollable_height"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from "@/components/general/NavBar.vue";
import ClientList from "@/components/dashboard/ClientList.vue";
export default {
    data() {
        return {
            filter_projects: ['all', 'stage 1', 'stage 2', 'stage 3', 'stage 4'],
            client_list_height: 'height: calc(100vh - 132px)',
            client_list_scrollable_height: 'height: calc((100vh - 132px) - 118px)'
        }
    },
    computed: {
    ...mapState([
          'enquiries'
          ])
    },
    components: {
        NavBar, ClientList
    }
}
</script>

<style lang="scss"></style>