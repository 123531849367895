<template>
    <div class="toggle_on_off">
        <div class="toggle_on_off__label">{{ title }}</div>
        <div class="toggle_on_off__switch" @click="clickedButton" :class="{inactive : toggleState == false}">
                <div class="toggle_on_off__switch____on">{{ option1 }}</div>
                <div class="toggle_on_off__switch____off">{{ option2 }}</div>
                <div class="toggle_on_off__switch____button"></div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'ToggleOnOff',
    props: ['toggleState', 'option1', 'option2', 'title'],
    methods: {
        clickedButton(){
            this.$emit('buttonClicked')
        }
    }
}

</script>

<style lang="scss" scoped>
.toggle_on_off {
    width: 100%;
    float: left;
}
.toggle_on_off__switch {
    position: relative;
    float: right;
    margin-top: -2px;
    width: 66px;
    height: 24px;
    border-radius: 20px;
    font-weight: 900;
    background-color: #1A86EA;
    text-transform: uppercase;
    cursor: pointer;
}
.toggle_on_off__label {
    float: left;
    height: 30px;
    color: #9C9FA0;
    font-weight: 900;
}

.toggle_on_off__switch____button {
    position: absolute;
    top: 3px;
    left: 44px;
    border-radius: 20px;
    background-color: #fff;
    width: 18px;
    height: 18px;
}

.toggle_on_off__switch.inactive {
    background-color: #A0A6AA;
}
.toggle_on_off__switch.inactive .toggle_on_off__switch____button {
    left: 4px;
}

.toggle_on_off__switch____on, .toggle_on_off__switch____off {
    color: #fff;
    height: 24px;
    line-height: 24px;
    font-size: 0.8em;
}

.toggle_on_off__switch____on {
    margin-left: 14px;
}

.toggle_on_off__switch____off {
    display: none;
    margin-left: 28px;
}

.toggle_on_off__switch.inactive .toggle_on_off__switch____off {
    display: block;
}

.toggle_on_off__switch.inactive .toggle_on_off__switch____on {
    display: none;
}
</style>