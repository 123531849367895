<template>
    <div class="references">
        <div class="references__number">
            <div class="references__label">Job No.</div>
            <input class="references__input" type="text" :value="this.enquiryId" placeholder="AUTO" disabled> 
        </div>
        <div class="references__date">
            <div class="references__label">{{this.ref_type }} DATE</div>
            <input class="references__input" type="date" v-model="todaysDate" @change="$emit('update-date', todaysDate)">
        </div>
        <span>{{rerunner}}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'ClientReferences',
    props: ['ref_type', 'enquiry'
        ],
    data(){
        return {
            todaysDate: '',
            enquiryId: ''
        }
    },
    computed: {
        rerunner(){
            //force EnquiryId to pull through when a new enquiry is created
            this.getEnquiryId()
            return null
        },
        ...mapState([
          'enquiries' 
          ])
    },
    methods: {
        getTodaysDate(){
            // console.log('getTodaysDate FIRED');
            //load date for existing enquiry or for new enquiries load today's date
            if(this.enquiry){
                this.todaysDate = this.enquiry.enquiry_date
            } else {
                var date = new Date();
                var dateDay = date.getUTCDate()
                var month = Number(date.getUTCMonth()) + 1
                var year = date.getUTCFullYear()
                var premonth = ""
                var preday = ""
                //trailing 0s need for date format
                if (month < 9) { //remember the month array start from 0
                    premonth = "0"
                }
                if (dateDay < 10) {
                    preday = "0"
                }
                var today = year + '-' + premonth + month + '-' + preday + dateDay
                this.todaysDate = today
            }
            this.$emit('update-date', this.todaysDate)
        },
        getEnquiryId(){
            if(this.enquiry){
                this.enquiryId = this.enquiry.enquiry_id
            } else {
                this.enquiryId = ""
            }
            this.$emit('update-id', this.enquiryId)
        },
    },
    created(){
        this.getTodaysDate()
        this.getEnquiryId()
    },
    updated(){
    }
}
</script>

<style lang="scss" scoped>
    .references {
        width: 100%;
        height: 90px;
        font-weight: 900;
        color: #9C9FA0;
    }
    .references__number {
        display: inline-block;
        width: 35%;
        height: 100%;
    }
    .references__date {
        display: inline-block;
        margin-left: 5%;
        width: 60%;
        height: 100%;
    }
    .references__label {
        padding-bottom: 10px;
        text-transform: uppercase;
    }
    .references__input {
        // font-family: 'Avenir';
        font-family: 'Montserrat', sans-serif;
        width: calc(95% - 10px);
        padding:0 5% 0 10px;
        border: 0px solid transparent;
        height: 40px;
        background-color: #fff;
        border-radius: 50px;
        font-size: 16px;
        color: #000;
        font-weight: 500;
        text-align: center;
    }
</style>