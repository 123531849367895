<template>
    <form action="" class="search__filter">
        <select id="search__filter__options" name="search__filter__options" class="search__filter__options" v-model="filterTerm" @change="setFilterTerm">
            <option v-for="(option, i) in options" :key="i" :value="option">
                {{ option }}
            </option>>
        </select>
    </form>
</template>

<script>
export default {
    name: 'SearchByFilter',
    props: {
        options: Array
    },
    data(){
        return {
            filterTerm: this.options[0]
        }
    },
    computed: {

    },
    methods: {
        setFilterTerm(){
            this.$emit('setFilterTerm', this.filterTerm)
        }
    }
}
</script>

<style lang="scss" scoped>
// RESETS
*,
*::before,
*::after {
  box-sizing: border-box;
}
select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    line-height: inherit;
    outline: none;
}
select::-ms-expand {
    display: none;
}
// RESETS END
.search__filter {
    position: absolute;
    right: 0;
    display: inline-block;
    width: 30%;
    height: 40px;
    border-radius: 50px;
    background-color: #2BB179;
    background-image: url('@/assets/icon_filter_dropdown.svg');
    background-repeat: no-repeat;
    background-position: 85% center;
    @media all and (max-width: 1124px) {
        width: 160px;
    }
    @media all and (max-width: 461px) {
        width: 45%
    }
}
.search__filter__options {
    border: 0px solid transparent;
    background-color: transparent;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    height: 40px;
    font-weight: 700;
    padding-left: 20px;
    width: calc(100% - 20px);
}
</style>