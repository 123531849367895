import { render, staticRenderFns } from "./MiniList.vue?vue&type=template&id=01312f96&scoped=true&"
import script from "./MiniList.vue?vue&type=script&lang=js&"
export * from "./MiniList.vue?vue&type=script&lang=js&"
import style0 from "./MiniList.vue?vue&type=style&index=0&id=01312f96&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01312f96",
  null
  
)

export default component.exports