<template>
    <div class="mini_list">
        <div class="mini_list__header">
            <div class="mini_list__title">{{ title }}</div>
            <div class="mini_list__button">
                <action-button :title="actionButtonTitle"/>
            </div>
        </div>
        <div class="mini_list__items">
            <ul class="mini_list__container">
                <li class="mini_list__row" v-for="item in list">
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ActionButton from "@/components/general/ActionButton.vue"
export default {
    name: 'MiniList',
    props: ['title', 'actionButtonTitle'],
    components: {
        ActionButton
    },
    data(){
        return {
            list: ['address 1', 'address 2', 'address 3']
        }
    }
}

</script>

<style lang="scss" scoped>

.mini_list {
    // height: calc((100vh - 272px)/2 );
    height: 232px;
}
.mini_list__title, .mini_list__button {
    display: inline-block;
}

.mini_list__items {
    margin-top: 20px;
    background-color: #EFEFEF;
    width: 100%;
    font-size: 16px;
}

.mini_list__header {
    position: relative;
    width: 100%;
}
.mini_list__title {
    font-weight: 900;
    color: #9C9FA0;
    padding-bottom: 10px;
    text-transform: uppercase;
}
.mini_list__button {
    right: 0;
    position: absolute;
}

ul.mini_list__container {
    list-style: none;
}

.mini_list__row {
    background-color: #fff;
    padding: 0 44px;
    width: calc(100% - 88px);
    height: 42px;
    line-height: 42px;
}

.mini_list__row:hover {
    background-color: #1A86EA;
    color: #fff;
    cursor: pointer;
}

ul.mini_list__container li:first-child.mini_list__row{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

ul.mini_list__container li:last-child.mini_list__row {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}


.mini_list__container {
            *::-webkit-scrollbar {
        background-color: transparent;
        width: 15px;
        }
        *::-webkit-scrollbar-track {
        background-color: transparent;
        }
        *::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 4px solid transparent;
        background-color: rgba(0,0,0,0.2);
        background-clip: content-box;
        }
    @media all and (max-width: 700px) {
        padding: 0 22px;
    }
}

.list__empty {
    background-color: #fff;
    color: #2BB179;
    width: calc(100% - 88px);
    margin: 0px 44px;
    font-size: 16px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-weight: 900;
    border-radius: 10px;
    text-transform: uppercase;
}
</style>