<template>
    <div class="nav">
            <div class="nav__buttons">
                <router-link to="/dashboard">
                    <div class="nav__button nav__button____icon">&nbsp;
                        <div id="icon1"></div>
                    </div>
                </router-link>
                <router-link to="/enquiries-list" :class="{active: enquiryRouteActive == true}" class="nav__button____enquiries">
                    <div class="nav__button">
                        Enquiries
                    </div>
                </router-link>
                <router-link to="/projects-list" :class="{active: projectRouteActive == true}" class="nav__button____projects">
                    <div class="nav__button">
                        Projects
                    </div>
                </router-link>
            </div>
            <div class="nav__logo"></div>
            <div class="nav__account">
                <router-link to="/account-settings">
                    <div class="nav__button nav__button____icon">&nbsp;
                        <div id="icon5"></div>
                    </div>
                </router-link>
            </div>
            <div class="nav__breadcrumb">
                {{ this.hasEnquiryId }} 
                <div class="nav__breadcrumb____all" :class="{nav__show: this.showAllBreadcrumb == true }">All</div>
                <div class="nav__breadcrumb____dashboard" :class="{nav__show: this.showDashboardBreadcrumb == true }">Dashboard</div>
                <div class="nav__breadcrumb____fee_letter" :class="{nav__show: this.showFeeLetterBreadcrumb == true }"><div class="nav__breadcrumb____bar"></div>Fee Letter</div>
            </div>
    </div>
</template>
    
<script>
import { mapState } from 'vuex';  

export default  {
    name: 'NavBar',
    props: ['enquiry_id'],
    data() {
        return {
            onLine: navigator.onLine
        }
    },
    computed: {
        hasEnquiryId(){
            if(this.enquiry_id != '' && this.enquiry_id != undefined) {
                return 'J-' + this.enquiry_id
            } 
            if(this.enquiry_id == '' || this.enquiry_id == undefined && window.location.pathname.match('/enquiry-screen')){
                return 'New'
            }
            // if( window.location.pathname.match('/enquiry-screen') && this.enquiry_id == ''){
            //     console.log(this.enquiry_id);
            //     return 'new'
            // }
        },
        enquiryRouteActive(){
            if(window.location.pathname.match('/enquiries-list') ||
               window.location.pathname.match('/enquiry-screen')) {
                return true
            } else {
                return false
            }
        },
        projectRouteActive(){
            if(window.location.pathname.match('/project-screen')) {
                return true
            } else {
                return false
            }
        },
        showDashboardBreadcrumb(){
            if(window.location.pathname.match('/dashboard')){
                return true
            } else {
                return false
            }
        },
        showAllBreadcrumb(){
            if(window.location.pathname.match('/enquiries-list') ||
               window.location.pathname.match('/projects-list')){
                return true
            } else {
                return false
            }
        },
        showFeeLetterBreadcrumb(){
            if(this.overlayFeeLetterActivate == true){
                return true
            } else {
                return false
            }
        },
        ...mapState([
          'overlayFeeLetterActivate'])
    },
    components: {
    },
    methods: {
    }
}
</script>
    
<style lang="scss">
.nav {
    position: relative;
    background-color: #235C76;
    width: 100%;
    height: 94px;
}
.nav__logo {
    position: absolute;
    display: inline-block;
    width: 150px;
    height: 30px;
    top: calc(50% - 35px);
    left: calc(50% - 75px);
    background-repeat: no-repeat;
    background-image: url(@/assets/harmony_logo.svg);
}
.nav__buttons {
    position: relative;
    display: inline-block;
    top: 0px;
    left: 0px;
    height: 54px;
    line-height: 54px;
    font-weight: 700;
    @media all and (max-width: 421px) {
    }
}
.nav__button {
    position: relative;
    padding: 0 27px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
}

.nav__button____icon {
    width: 30px;
    height: 54px;
}

#icon1 {
    position: absolute;
    background-repeat: no-repeat;
    top: calc(50% - 20px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    background-image: url(@/assets/icon_dashboard_light.svg);
    @media all and (max-width: 421px) {
    }
}

#icon5 {
    position: absolute;
    background-repeat: no-repeat;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 30px;
    height: 30px;
    background-image: url(@/assets/icon_account_light.svg);
    @media all and (max-width: 421px) {
    }
}
.nav__account {
    position: absolute;
    top: 0;
    right: 0px;
}

.nav__breadcrumb {
    padding-left: 30px;
    color: #fff;
    font-weight: 700;
    position: relative;
    height: 40px;
    line-height: 40px;
    width: 100%;
    background-color: #7ABCBC;
}
.nav__breadcrumb____fee_letter,
.nav__breadcrumb____dashboard,
.nav__breadcrumb____all {
    display: none;
}

.nav__show {
    display: inline-block;
}

.nav__breadcrumb____bar {
    position: relative;
    top: -4px;
    margin: 0 20px;
    width: 46px;
    height: 3px;
    background-color: #fff;
    display:inline-block
}

.router-link-exact-active .nav__button,
.active .nav__button
{
    background-color: #7ABCBC;
}

.nav__button____projects {
    @media all and (max-width: 961px) {
        display: none;
    }
}

.nav__button____enquiries {
    @media all and (max-width: 700px) {
        display: none;
    }
}

</style>