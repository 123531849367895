<template>
    <div>
        <div class="overlay_fee_letter">
            <div class="overlay_fee_letter__left">
                <!-- <div class="overlay_fee_letter__title">FEE LETTER / E-{{ this.enquiry_id }}</div> -->
                <issue-date-picker @update-date="updateDate"/>
                <price-box @update-price="updatePrice" :fee_letter_price="fee_letter_price" :enquiry="enquiry" :saved="saved" :template_name="template_name" />
                <set-fee-letter-template :options="filter_templates" @update-status="updateStatus" :enquiry="enquiry" :template_name="template_name" :saved="saved" :fee_letter="fee_letter" v-if="allEnquriesLoaded == true"/> <br>
                <action-button :title="this.isFeeLetter" @buttonClicked="saveFeeLetter"/>
                <action-button :title="`Download PDF`" @buttonClicked="triggerDownload" :status="canDownload"/>
                <br><br><br><br>
                <toggle-on-off @buttonClicked="toggleHeaderFooter" :toggleState="headerFooterVisible" :option1="`on`" :option2="`off`" :title="`HEADER/FOOTER`"/>
                <br><br><br>
                <toggle-on-off @buttonClicked="toggleNotes" :toggleState="notesVisible" :option1="`on`" :option2="`off`" :title="`NOTES`"/>
                <enquiry-notes :enquiry="enquiry" v-if="this.showNotes == true"/>
            </div>
            <div class="overlay_fee_letter__mid">
                <fee-letter-preview :fee_letter="fee_letter" :enquiry="enquiry" :fee_letter_date="fee_letter_date" :fee_letter_ref="fee_letter_ref" @update-fee-letter-reference="updateFeeLetterReference" :showHeaderFooter="showHeaderFooter"/>
            </div>
        </div>
        <div class="overlay_fee_letter__close_bg">
            <div class="overlay_fee_letter__close_btn" @click="closeFeeLetter">Close Fee Letter&nbsp;&nbsp;&#10005;</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';   
import ClientReferences from "@/components/enquiry/ClientReferences.vue";
import ToggleOnOff from "@/components/general/ToggleOnOff.vue";
import SetFeeLetterTemplate from "@/components/enquiry/OverlayFeeLetter/SetFeeLetterTemplate.vue";
import ActionButton from "@/components/general/ActionButton.vue";
import EnquiryNotes from "@/components/enquiry/EnquiryNotes.vue";
import FeeLetterPreview from "@/components/enquiry/OverlayFeeLetter/FeeLetterPreview.vue";
import IssueDatePicker from "@/components/enquiry/OverlayFeeLetter/IssueDatePicker.vue";
import PriceBox from "@/components/enquiry/OverlayFeeLetter/PriceBox.vue";
export default {
    name: 'OverlayFeeLetter',
    components: {
        ToggleOnOff, IssueDatePicker, SetFeeLetterTemplate, EnquiryNotes, FeeLetterPreview, ActionButton, ClientReferences, PriceBox
    },
    props: ['enquiry_id', 'enquiry', 'templates', 'fee_letter', 'fee_letter_date', 'fee_letter_price', 'fee_letter_ref', 'can_download', 'template_name', 'allEnquriesLoaded', 'saved'],
    data(){
        return {
            ref_type: "Enquiry",
            filter_templates: ['1', '2', '3'],
            template_selected: '',
            base_template: '',
            showHeaderFooter: true,
            showNotes: false
        }
    },
    computed: {
        canDownload(){
            if(this.can_download){
                return 'active'
            } else {
                return 'disabled'
            }
        },
        headerFooterVisible(){
            if(this.showHeaderFooter){
                return true
            } else {
                return false
            }
        },
        notesVisible(){
            if(this.showNotes){
                return true
            } else {
                return false
            }
        },
        ...mapState([
          'enquiries', 'enquiriesList', 'enquiriesLoaded', 'enquiriesLoaded',
          'projectsLoaded', 'templatesLoaded'
          ]),
          getEnquiry(){
            var neededEnquiryNo = this.enquiry_id;
            for (let i = 0; i < this.enquiries.length; i++){
                if (this.enquiries[i].details.enquiry_id == neededEnquiryNo) {
                    return this.enquiries[i].details
                }
            }
            return null
        },
        isFeeLetter(){
            if(this.enquiry.enquiry_fee_letter.ref == null || this.enquiry.enquiry_fee_letter.ref == ''){
                return `Save Letter`
            } else {
                return `Update Letter`
            }
        }
    },
    methods: {
        createFeeLetter(){
            console.log('createFeeLetter fired');
            if(this.enquiry.enquiry_fee_letter.ref == '' || this.enquiry.enquiry_fee_letter == ''){
                console.log('try to parse new record from selected template and user data');
                //second condition is for older records created while testing, new enquiries for the real system wont need that
                // var targetTemplate = {};
                var fullTemplate = {}
                if(this.template_selected != ''){
                    //GET MATCHING TEMPLATE VIA KEY
                    for (var k = 0; k < this.templates.length; k++){
                        if (this.templates[k].key == this.template_selected){
                            console.log('the matching template was found');
                            console.log(this.templates[k]);
                            //JSON CONVERSION BREAKS THE CONNECTION TO THE ORIGINAL OBJECT SO WE CAN CUSTOMISE
                            fullTemplate = JSON.parse(JSON.stringify(this.templates[k]))
                        }
                    }
                    // this.base_template = fullTemplate
                    this.$emit('update-fee-letter', fullTemplate)
                }
            } else {
                //UPDATE FROM EXISTING RECORD
                console.log('update from existing record, which means the variables have already been parsed')
                // console.log(this.enquiry.enquiry_fee_letter.template);
                var existing = {'details': '', 'key' : ''}
                existing.details = this.enquiry.enquiry_fee_letter.template
                existing.key = this.enquiry.enquiry_fee_letter.template
                this.$emit('update-fee-letter', existing)
            }
        },
        saveFeeLetter(){
            this.$emit('save-fee-letter')
        },
        updateDate(date){
            this.$emit('update-date', date)
        },
        updateStatus(status){
            console.log('updateStatus FIRED will trigger a createFeeLetter()');
            this.template_selected = status[0]
            this.$emit('update-status', status)
            this.createFeeLetter()
        },
        updatePrice(price){
            console.log('updatePrice FIRED will trigger a createFeeLetter()');
            this.$emit('update-price', price)
            this.createFeeLetter()
        },
        updateFeeLetterReference(ref){
            this.$emit('update-fee-leter-reference', ref)
        },
        closeFeeLetter(){
            this.$store.dispatch('setOverlayFeeLetterInactivate')
        },
        toggleHeaderFooter(){
            if(this.showHeaderFooter == true){
                this.showHeaderFooter = false
            } else {
                this.showHeaderFooter = true
            }
        },
        toggleNotes(){
            if(this.showNotes == true){
                this.showNotes = false
            } else {
                this.showNotes = true
            }
        },
        triggerDownload(){
            var docName = 'Fee-Letter-PW' + this.enquiry.enquiry_fee_letter.ref + '-Neunie-Associates-' + this.enquiry.enquiry_fee_letter.date + '.pdf'
				const element = document.querySelector('.letter_preview__holder');
                html2pdf()
                .set({ html2canvas: { scale: 4 }, jsPDF: { unit: 'pt', format: 'A4'}, pagebreak: { mode: ['avoid-all', 'css', 'legacy'] } })
                .from(element)
                .save(docName);
        }
    },
    created(){
        let html2pdf = document.createElement('script')
            html2pdf.setAttribute('src', '../libs/html2pdf.bundle.min.js')
            document.head.appendChild(html2pdf)
    }
}

</script>

<style lang="scss" scoped>
.overlay_fee_letter {
    position: absolute;
    top: 94px;
    left: 0;
    background-color: #EFEFEF;
    width: 100%;
    min-width: 1200px;
}
.overlay_fee_letter__left {
    float: left;
    min-width: 300px;
    width: calc(30% - 88px);
    height: 100%;
    padding: 0 44px;
    padding-top: 22px;
}

.overlay_fee_letter__mid {
    padding-top: 22px;
    float: left;
    width: 40%;
    width: 21cm;
    height: 100%;
}

.overlay_fee_letter__right {
    float: left;
    width: calc(30% - 88px);
    height: 100%;
    padding: 0px 44px
}

.overlay_fee_letter__close_bg {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 94px;
    background-color: rgba(225,225,225, 0.5);
}

.overlay_fee_letter__close_btn {
    position: absolute;
    bottom: 7px;
    right: calc(100% - 30% - 21cm);
    height: 16px;
    line-height: 16px;
    padding: 3px 20px;
    border-radius: 20px;
    border: 2px solid #fff;
    border: 2px solid transparent;
    background-color: #8833FF;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
}

.overlay_fee_letter__close_btn:hover {
    background-color: #1A86EA;
}

.overlay_fee_letter__title {
    height: 60px;
    padding-top: 20px;
    font-size: 26px;
    font-weight: 700;
    color: #BAB7B7;
}

.action_button {
    margin-top: 20px;
    width: calc(100% - 48px);
    text-align: center
}
</style>