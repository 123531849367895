<template>
    <div v-if="allEnquriesLoaded == true">
       <nav-bar :enquiry_id="enquiry_id"/>
       <div class="enquiry_screen">
           <div class="enquiry_screen__left">
                <project-set-status :options="filter_enquiries" :enquiry="getEnquiry" @update-project-status="updateStatus"/>
                <agreed-surveyor/>
                
                
                <client-details :enquiry="getEnquiry" :ref_type="this.ref_type"/>
                <action-button :title="`Save Project`"/><br><br><br>
                <toggle-on-off @buttonClicked="toggleNotes" :toggleState="notesVisible" :option1="`on`" :option2="`off`" :title="`NOTES`"/>
                <enquiry-notes :enquiry="getEnquiry" :title="``" v-if="this.showNotes == true"/>
                <br><br><br>
               
           </div>
           <div class="enquiry_screen__mid">
                <div class="enquiry_screen__mid____header"></div>
                <mini-list :title="`ADJOINING OWNERS`" :actionButtonTitle="`Edit`"/>
                <mini-list :title="`PARTY WALL NOTICES`" :actionButtonTitle="`Create`"/>
           </div>
           <div class="enquiry_screen__right">
                <enquiry-link :enquiry="getEnquiry"/>
                <file-list :list_title="`Agreed Surveyor Letter`"/>
                <file-list :list_title="`Supporting Documents`"/>
                <file-list :list_title="`S.O.C`"/>
                <file-list :list_title="`Award`"/>
           </div>
       </div>
       <overlay-fee-letter v-if="this.overlayFeeLetterActivate == true"
                           :templates="templates" 
                           :fee_letter="enquiry.enquiry_fee_letter.template" 
                           :template_name="enquiry.enquiry_fee_letter.template_name" 
                           :fee_letter_date="enquiry.enquiry_fee_letter.date" 
                           :fee_letter_ref="enquiry.enquiry_fee_letter.ref" 
                           :fee_letter_price="enquiry.enquiry_fee_letter.price" 
                           :enquiry_id="enquiry_id" 
                           :enquiry="getEnquiry" 
                           :can_download="this.isReadyToDownload"
                           :saved="this.isFeeLetterSaved"
                           :allEnquriesLoaded="allEnquriesLoaded"
                           />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from "@/components/general/NavBar.vue";
import ToggleOnOff from "@/components/general/ToggleOnOff.vue";
import ClientReferences from "@/components/enquiry/ClientReferences.vue";
import ClientDetails from "@/components/enquiry/ClientDetails.vue";
import ProjectSetStatus from "@/components/project/ProjectSetStatus.vue";
import AgreedSurveyor from "@/components/project/AgreedSurveyor.vue";
import MiniList from "@/components/project/MiniList.vue";
import ActionButton from "@/components/general/ActionButton.vue";
import FileList from "@/components/general/FileList.vue";
import ServiceDetails from "@/components/enquiry/ServiceDetails.vue";
import EnquiryLink from "@/components/project/EnquiryLink.vue";
import EnquiryNotes from "@/components/enquiry/EnquiryNotes.vue";
import OverlayFeeLetter from "@/components/enquiry/OverlayFeeLetter.vue";
import FeeLetterList from "@/components/enquiry/FeeLetterList.vue";
export default {
    name: 'ProjectScreen',
    components: {
        NavBar, ToggleOnOff, ClientReferences, ProjectSetStatus, ClientDetails, ActionButton, ServiceDetails, EnquiryLink, EnquiryNotes, FeeLetterList, OverlayFeeLetter, AgreedSurveyor, MiniList, FileList
    },
    data(){
        return {
            editing: false,
            ref_type: "Project",
            showNotes: false,
            filter_enquiries: ['stage 1', 'stage 2', 'stage 3', 'stage 4'],
            enquiry: {
                enquiry_id: '',
                enquiry_date : "",
                    client_details : {
                    name : "",
                    address_line_1 : "",
                    address_line_2 : "",
                    postcode : "",
                    email : "",
                    mobile : "",
                    landline : ""
                    },
                service_details : "",
                service_length : "",
                status : "",
                notes : [],
                enquiry_fee_letter : {
                    price : "", 
                    template : [ 
                    ],
                    template_name : "",
                    date : "",
                    ref: "",
                    saved: false
                },
                project: {
                    started: false,
                    status: "",
                    agreed_surveyor: "",
                    agreed_surveyor_name: "",
                    agreed_surveyor_letter: {},
                    adjoining_owners: [],
                    party_wall_notices: [],
                    supporting_documents: [],
                    soc: "",
                    award: ""
                }
            }
        }
    },
    props: ['enquiry_id'],
    computed: {
        notesVisible(){
            if(this.showNotes){
                return true
            } else {
                return false
            }
        },
        getEnquiry(){
            var neededEnquiryNo = this.enquiry_id;
            for (let i = 0; i < this.enquiries.length; i++){
                if (this.enquiries[i].details.enquiry_id == neededEnquiryNo) {
                    return this.enquiries[i].details
                }
            }
            return null
        },
        isFeeLetter(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter.ref != '' && enquiry.enquiry_fee_letter != ''){
                    return `Open Fee Letter`
                } else {
                    return `Create Fee Letter`
                }
            } else {
                return `Create Fee Letter`
            }
        },
        isFeeLetterSaved(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter.saved){
                    return enquiry.enquiry_fee_letter.saved
                } else {
                    return this.enquiry.enquiry_fee_letter.saved
                }
            } else {
                return this.enquiry.enquiry_fee_letter.saved
            }
        },
        hasFeeLetterReference(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter.ref != ''){
                    return enquiry.enquiry_fee_letter.ref
                } else {
                    return this.enquiry.enquiry_fee_letter.ref
                }
            } else {
                return this.enquiry.enquiry_fee_letter.ref
            }
        },   
        isEnableToCreateFeeLetter(){
            if(this.getEnquiry != undefined){
                return true
            } else {
                return false
            }
        },
        isReadyToDownload(){
            var enquiry = this.getEnquiry
            if(enquiry){
                if(enquiry.enquiry_fee_letter == '' || enquiry.enquiry_fee_letter.ref == ''){
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        allEnquriesLoaded(){
            //checking whether enquiries have loaded lets the components wait for the data before loading
            //especially needed for if the page gets reloaded, otherwise the component loads before app.js can fetch the data
            return (this.enquiriesLoaded ? true : false)
        },
        enquiryButtonTitle(){
            if(this.enquiry.enquiry_id == ''){
                return `Save Enquiry`
            } else {
                return `Update Enquiry`
            }
        },
    ...mapState([
          'enquiries', 'enquiriesList', 'enquiriesLoaded', 'enquiriesLoaded',
          'projectsLoaded', 'overlayFeeLetterActivate', 'templates'
          ])
  },
  methods: {
        toggleNotes(){
            if(this.showNotes == true){
                this.showNotes = false
            } else {
                this.showNotes = true
            }
        },
        // updateClientDetails(details){
        //     // will need some validation before making the records, maybe do it in each component
        //     this.enquiry.client_details = details
        // },
        updateStatus(status){
            // console.log('existing updateStatus');
            this.enquiry.project.status = status
        },
        // updateTemplate(template){
        //     // console.log('updateTemplate fired');
        //     // console.log(template);
        //     this.enquiry.enquiry_fee_letter.template_name = template[0]
        //     this.enquiry.enquiry_fee_letter.saved = template[1]
        // },
        // updateDate(date){
        //     this.enquiry.enquiry_date = date
        //     // this.editing = true
        // },
        // updateNotes(notes){
        //     this.enquiry.notes = notes
        //     // this.editing = true
        // },
        // updateId(id){
        //     if(this.enquiry.enquiry_id == ''){
        //         this.enquiry.enquiry_id = id
        //     }
        // },
        // updateServiceDetails(option){
        //     this.enquiry.service_details = option
        //     // console.log(this.enquiry.service_details);
        //     // this.editing = true
        // },
        // updateServiceLength(option){
        //     this.enquiry.service_length = option
        //     // console.log(this.enquiry.service_length);
        //     // this.editing = true
        // },
        // updateFeeLetterDetails(template){
        //     console.log('updateFeeLetterDetails fired');
        //     var enquiry = this.getEnquiry

        //     var preppedTemplate = [];
        //     for (var k = 0; k < template.details.length; k++){

        //         var raw = template.details[k].page

        //         for (var i = 0; i < raw.length; i++){
        //             raw[i].content = raw[i].content.replaceAll("{{name}}", enquiry.client_details.name)
        //             raw[i].content = raw[i].content.replaceAll("{{address_line_1}}", enquiry.client_details.address_line_1)
        //             raw[i].content = raw[i].content.replaceAll("{{address_line_2}}", enquiry.client_details.address_line_2)
        //             raw[i].content = raw[i].content.replaceAll("{{postcode}}", enquiry.client_details.postcode)
        //             raw[i].content = raw[i].content.replaceAll("{{price}}", this.enquiry.enquiry_fee_letter.price)
        //         }
        //         var pageToAdd = {'page': raw} 

        //         preppedTemplate.push(pageToAdd)
        //     }
        //     this.enquiry.enquiry_fee_letter.template = preppedTemplate
        //     // this.editing = true
                        
        // },
        // updateFeeLetterDate(date){
        //     this.enquiry.enquiry_fee_letter.date = date
        // },
        // updateFeeLetterPrice(price){
        //     console.log('updateFeeLetterPrice FIRED');
        //     this.enquiry.enquiry_fee_letter.price = price
        // },
        // updateFeeLetterReference(ref){
        //     this.enquiry.enquiry_fee_letter.ref = ref
        // },
        // getSavedTemplate(){
        //     var enquiry = this.getEnquiry
        //     if(enquiry.enquiry_fee_letter.template_name != ''){
        //         this.enquiry.enquiry_fee_letter.template_name = enquiry.enquiry_fee_letter.template_name
        //     }
        // },
        // saveFeeLetter(){
        //     console.log('saveFeeLetter FIRED, should set fee letter in the record to true');
        //     //will need to set the fee letter data to saved
        //     this.enquiry.enquiry_fee_letter.saved = true
        //     this.saveEnquiry()
        // },
        // openFeeLetter(){
        //     console.log('openFeeLetter called');
        // },
        // saveEnquiry(){
        //     //this.enquiry is the one we want to create or update
        //     if(this.enquiry.enquiry_id == ''){
        //         console.log('THIS IS A NEW ENQUIRY AND NEEDS TO BE CREATED');
        //         var getEnquiriesList = this.enquiriesList;
        //         var enquiriesListInOrder = getEnquiriesList.slice().sort((a,b)=>a-b)
        //         var highestId = enquiriesListInOrder[enquiriesListInOrder.length - 1];
        //         var newEnquiryNumber = Number(highestId) + 1;
        //             enquiriesListInOrder.push(newEnquiryNumber.toString())
        //         var list = enquiriesListInOrder
        //         var newKey = newEnquiryNumber;
        //         var details = this.enquiry
        //         if(details.enquiry_id == ''){
        //             details.enquiry_id = newKey;
        //         }
        //         var bundle = [newKey, list, details]
        //         var newEnquiryRoute = "/enquiry-screen/" + newKey
        //         this.$store.dispatch('saveToServer', bundle)
        //         this.$router.push(newEnquiryRoute)
        //         this.updateId()
        //         this.editing = false

        //     } else {
        //         console.log('ENQUIRY EXISTS ALREADY AND NEEDS TO BE UPDATED');
        //         var details = this.enquiry
        //         var existingKey = this.enquiry_id
        //             existingKey = existingKey.toString()
        //         var bundle = [existingKey, details]
        //         console.log(bundle);
        //         this.$store.dispatch('updateToServer', bundle)
        //         this.editing = false
        //     }
        // }
  },
  updated(){
    // this.getSavedTemplate()
  }
}
</script>

<style lang="scss" scoped>
.enquiry_screen {
    width: 100%;
    height: calc(100vh - 132px - 44px);
    padding: 22px 0px;
    background-color: #EFEFEF;
    @media all and (max-width: 1024px) {
        height: 1248px;
    }
}
.enquiry_screen__left {
    float: left;
    width: calc(30% - 88px);
    padding: 0px 44px;
    height: 100%;
    @media all and (max-width: 1024px) {
        width: calc(100% - 44px);
        padding: 0px 22px;
        padding-bottom: 30px;
        height: 540px;
    }
}

.enquiry_screen__mid {
    float: left;
    width: 40%;
    height: 100%;
    @media all and (max-width: 1024px) {
        width: calc(100% - 44px);
        padding: 0px 22px;
        height: 700px;
    }
}

.enquiry_screen__mid____header{
    height: 196px;
}

.enquiry_screen__right {
    float: left;
    width: 30%;
    height: 100%;
    @media all and (max-width: 1024px) {
        display: none;
    }
}

.action_button {
    margin-top: 10px;
    width: calc(100% - 48px);
    text-align: center;
}

.mob__hide {
    @media all and (max-width: 1024px) {
        display: none;
    }
}
.mob__show {
    display: none;
    @media all and (max-width: 1024px) {
        display: block;
    }
}
</style>