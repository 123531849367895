<template>
    <div class="overlay" :class="{overlay__active: overlay == true && forgottenPW == true}">
        <div class="overlay__background" @click="hideOverlay"></div>
        <div class="overlay__list">
            <div class="reset__account__form">
                <div class="quick_look__close_btn" @click="hideOverlay"></div>
                <div class="create__account__form____title">
                    Reset Password
                </div>
                <div class="create__account__form____subheading">
                    Enter your email and we'll send you a reset link.
                    <form class="signup_form" @submit.prevent="resetUser">
                        <input class='resetUser____1_col' type='email' v-model='reset_email' required placeholder='Email address'/>

                            <input class='resetUser____2_col resetUser____center resetUser____button button highlight' type='submit' value='Send me the link' name='create-account'/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
// import firebaseDB from "@/services/firebase.js"
import { mapState } from 'vuex';

export default {
    data(){
      return {
          reset_email: '',
      }
    },
    name: 'OverlayForgottenPassword',
    components: {
        // OverlayRecipesPanel
        },
    computed: {
        ...mapState(['overlay', 'months', 'forgottenPW']),
    },
    methods: {
        hideOverlay(){
            this.$store.dispatch('setOverlayToFalse')
            this.$store.dispatch('setCreateAccountToFalse')
            this.$store.dispatch('setForgottenPWToFalse')
            this.iosSetScrollTop()
        },
        resetUser(){
            const User = {
              reset_email: this.reset_email,
          }

        firebase.auth().sendPasswordResetEmail(User.reset_email)
        .then(() => {
            //notify user that that it was successful
            this.hideOverlay()

        })
        .catch((error) => {
            console.log(error);
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // ..
        });


        },
        iosSetScrollTop(){
            //on some iOS devices, the onscreen keyboard for login page, sets the document scrollTop
            //above the viewport and its stuck there until a user tries to scroll down, breaking the UI
            // same function is in app file
            document.body.scrollTop = 0
            console.log('iosSetScrollTop triggered');
        }
    }
}
</script>

<style lang="scss">
// @import "@/scss/_variables.scss";
.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    display: none;
}
.overlay__active {
    visibility: visible;
    opacity: 1;
    display: block;
}

.overlay__background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:#000;
    opacity: 0.9;
}

.overlay__list {
    position: absolute;
    width: 600px;
    height: 100%;
    margin-left: calc(50% - 300px);
    margin-right: calc(50% - 300px);
    @media all and (max-width: 600px) {
        width: 100%;
        margin: 0;
    }
}

.quick_look__close_btn {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 30px;
    height: 30px;
    // background-image: url('../assets/img/icon_line_submenu.svg');
    background-size: contain;
}

.quick_look__close_btn:hover {
    cursor: pointer;
}

.reset__account__form {
    // font-family: $font-stack;
    position: relative;
    margin-top: 12.5em;
    background-color: #fff;
    border-radius: 15px;
    padding: 0 1.5em
}

// .create__account__form____title {
//     font-size: 2em;
//     font-weight: 700;
//     @media all and (max-width: 600px) {
//         margin-top: 1em;
//     }
// }

// .create__account__form____subheading {
//     font-size: 1.4em;
// }

.resetUser____1_col, .resetUser____2_col {
    padding: 0.8em 1em;
    margin-top: 1em;
    font-size: 0.9em;
    border-radius: 30px;
    border: 0;
    background-color: #D8D8D8;
}

.resetUser____center {
    margin-left: calc(25% + 1em);
    @media all and (max-width: 600px) {
        margin-left: 1em;
    }
}

.resetUser____1_col {
    width: calc(100% - 2em);
}

.resetUser____2_col {
    width: calc(50% - 2em);
    @media all and (max-width: 600px) {
        width: calc(100% - 2em);
    }
}


.createUser____subheading {
    margin: 1em 0 0.5em 0;
    font-size: 0.8em;
    font-weight: 700;
    color: #0C4142;
}

.signup_form {
    position: relative;
}

.resetUser____button {
    background-color: #0EA302;
    color: #fff;
    margin-bottom: 1.5em;
}

input[type=submit]:hover {
    cursor: pointer;
}

</style>