import Vue from 'vue'
import Vuex from 'vuex'
import firebaseDB from "@/services/firebase.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    enquiries: [],
    enquiriesList: [],
    projects: [],
    projectsList: [],
    templates: [],
    templatesList: [],
    enquiriesSearched: [],
    projectsSearched: [],
    enquiriesLoaded: false,
    projectsLoaded: false,
    templatesLoaded: false,
    pullTimeStamp: '',
    reloadRequired: false,
    overlayFeeLetterActivate: false,
    uid: ''
  },
  mutations: {
    SET_UID(state, uid){
      state.uid = uid
    },
    SET_ENQUIRIES(state, enquiries){
      state.enquiries = enquiries
    },
    SET_PROJECTS(state, projects){
      state.projects = projects
    },
    SET_TEMPLATES(state, templates){
      state.templates = templates
    },
    SET_USER_AS_LOGGED_IN(state, userUID){
      localStorage.setItem('user', userUID)
      state.uid = userUID;
      state.isLoggedIn = true;
    },
    //SYNC CONFLICT SETUP
    SET_PULL_TIMESTAMP(state, timestamp){
      state.pullTimeStamp = timestamp
    },
    SET_ENQUIRIES_LIST(state, list){
      state.enquiriesList = list
    },

    SET_TEMPLATES_LIST(state, list){
      state.templatesList = list
    },

    SAVE_TO_SERVER(state, update){
      var newEnquiry = {}
          newEnquiry.details = update[2]
          newEnquiry.key = update[0]

      state.enquiries.push(newEnquiry)
      state.enquiriesList = update[1] //for display
      state.enquiries.all = update[1]
    },
    UPDATE_TO_SERVER(state, update){
      var updatekey = update[0]
      var updatedetails = update[1]
          updatekey = Number(updatekey)

      for (var i = 0; i < state.enquiries.length; i++){
        if(state.enquiries[i].key == updatekey ){
          state.enquiries[i].details = updatedetails
        }
      }
    },
    SET_ENQUIRIES_LOADED(state){
      state.enquiriesLoaded = true
    },
    SET_PROJECTS_LOADED(state){
      state.projectsLoaded = true
    },
    SET_TEMPLATES_LOADED(state){
      state.templatesLoaded = true
    },
    CLEAR_STATE(state){
        state.enquiries = [];
        state.enquiriesList = [];
        state.projects = [];
        state.projectsList = [];
        state.templates = [];
        state.templatesList = [];
        state.enquiriesSearched = [];
        state.projectsSearched = [];
        state.enquiriesLoaded = false;
        state.projectsLoaded = false;
        state.templatesLoaded = false;
        state.reloadRequired = false;
        state.overlayFeeLetterActivate = false;
        state.uid = '';
        state.pullTimeStamp = '';
    },

     SET_USER_AS_LOGGED_OUT(){
      localStorage.removeItem('user');
    },

    SET_OVERLAY_FEE_LETTER_ACTIVATE(state){
        state.overlayFeeLetterActivate = true
    },

    SET_OVERLAY_FEE_LETTER_INACTIVATE(state){
      state.overlayFeeLetterActivate = false
  }
    
  },
  actions: {
    setUID({commit}, uid){
      commit('SET_UID', uid)
    },
    setEnquiries({commit}, enquiries){
      commit('SET_ENQUIRIES', enquiries)
    },
    setProjects({commit}, projects){
      commit('SET_PROJECTS', projects)
    },
    setTemplates({commit}, templates){
      commit('SET_TEMPLATES', templates)
    },
    //USER LOGIN
    logUserIn({commit}, userUID){
      commit('SET_USER_AS_LOGGED_IN', userUID)
    },
    //SYNC CONFLICT SETUP
    setPullTimeStamp({commit}, timestamp){
      commit('SET_PULL_TIMESTAMP', timestamp)
    },
    setEnquiriesList({commit}, list){
      commit('SET_ENQUIRIES_LIST', list)
    },
    setEnquiriesLoaded({commit}){
      commit('SET_ENQUIRIES_LOADED')
    },
    setProjectsLoaded({commit}){
      commit('SET_PROJECTS_LOADED')
    },
    setTemplatesList({commit}, list){
      commit('SET_TEMPLATES_LIST', list)
    },
    setTemplatesLoaded({commit}){
      commit('SET_TEMPLATES_LOADED')
    },

    clearState({commit}){
      commit('CLEAR_STATE')
    },

    logUserOut({commit}){
      commit('SET_USER_AS_LOGGED_OUT')
    },

    //SAVING TO THE SERVER
    saveToServer({commit, state}, update){
      // add check to see if database has been updated since the last time it was pull
      // key, list, details
      var keyToSave = JSON.stringify(update[0])
      var listToSave = JSON.stringify(update[1])
      var detailsToSave = JSON.stringify(update[2])
      commit('SAVE_TO_SERVER', update)
      
      firebaseDB.collection("users").doc(state.uid).collection('enquiries').doc(keyToSave).set({
        details: detailsToSave,
        key: keyToSave
      })

       firebaseDB.collection("users").doc(state.uid).collection('enquiries').doc('all').update({
        list: listToSave
      })
    },
     //UPDATE SERVER
    updateToServer({commit, state}, update){
      // key, details
      commit('UPDATE_TO_SERVER', update)
      var keyToSave = update[0]
      var detailsToSave = JSON.stringify(update[1])
      
      firebaseDB.collection("users").doc(state.uid).collection('enquiries').doc(keyToSave).update({
        details: detailsToSave
      })
    },

    setOverlayFeeLetterActivate({commit}){
      commit('SET_OVERLAY_FEE_LETTER_ACTIVATE')
    },
    
    setOverlayFeeLetterInactivate({commit}){
      commit('SET_OVERLAY_FEE_LETTER_INACTIVATE')
    }
  },
  modules: {
  }
})
