<template>
    <div class="details" :class="{disabled : this.isProject == true}">
            <div class="details__label">Client Details</div>
            <div class="details__list____holder">
                <ul>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.name" placeholder="Full Name" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.address_line_1" placeholder="Address Line 1" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.address_line_2" placeholder="Address Line 2" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.postcode" placeholder="Postcode" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.email" placeholder="Email" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.mobile" placeholder="Mobile" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                    <li>
                        <input class="details__list" type="text" v-model="clientDetails.landline" placeholder="Landline" @blur="$emit('update-client-details', clientDetails)" :disabled="this.isProject == true"/>
                    </li>
                </ul>

            </div>
            <!-- <span>{{ rerunner }}</span> -->
    </div>
</template>

<script>
export default {
    name: 'ClientDetails',
    props: ['enquiry', 'ref_type'],
    data(){
        return {
            clientDetails: {
                name: "",
                address_line_1: "",
                address_line_2: "",
                postcode: "",
                email: "",
                mobile: "",
                landline: ""
            }
        }
    },
    computed:{
        rerunner(){
            this.setClientDetails()
            return null
        },
        isProject(){
            if(this.ref_type == 'Project'){
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        setClientDetails(){
            //if enquiry exists, load it, otherwise fill field blank and ready to be fille in
            if(this.enquiry){
                this.clientDetails.name = this.enquiry.client_details.name
                this.clientDetails.address_line_1 = this.enquiry.client_details.address_line_1
                this.clientDetails.address_line_2 = this.enquiry.client_details.address_line_2
                this.clientDetails.postcode = this.enquiry.client_details.postcode
                this.clientDetails.email = this.enquiry.client_details.email
                this.clientDetails.mobile = this.enquiry.client_details.mobile
                this.clientDetails.landline = this.enquiry.client_details.landline
                this.$emit('update-client-details', this.clientDetails)
            }
        }
    },
    created(){
        this.setClientDetails()
    }
}
</script>

<style lang="scss" scoped>
.details {
    padding-top: 30px;
}
.details__label {
    font-weight: 900;
    text-transform: uppercase;
    color: #9C9FA0;
}
.details__list {
    height: 42px;
    line-height: 42px;
    width: calc(100% - 44px);
    padding: 0 22px;
    background-color: #fff;
    border-bottom: 1px solid #EFEFEF;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
.details ul li:first-child input{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.details ul li:last-child input{
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.details__list____holder {
    padding: 10px 0
}

.disabled .details__list {
    background-color: #E8E8E8;
}

ul{
    list-style-type: none;
}

input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border: 0px solid transparent;
    padding: 0;
}
</style>