<template>
    <div class="action_button" :class="{disabled : status == 'disabled'}" @click="clickedButton">
        {{ title }}
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        status: String
    },
    methods: {
        clickedButton(){
            this.$emit('buttonClicked')
        }
    }
}
</script>

<style lang="scss" scoped>
.action_button {
    display: inline-block;
    color: #fff;
    font-weight: 900;
    background-color: #8833FF;
    height: 20px;
    line-height: 20px;
    padding: 10px 24px;
    border-radius: 50px
}

.action_button:hover {
    cursor: pointer;
    background-color:#1A86EA;
}

.disabled {
    pointer-events: none;
    color: #DEDADA;
    background-color: #BAB7B7;
    user-select: none;
}
</style>