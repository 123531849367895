<template>
    <div class="overlay" :class="{overlay__active: overlay == true && createAccount == true}">
        <div class="overlay__background" @click="hideOverlay"></div>
        <div class="overlay__list">
            <div class="create__account__form">
                <div class="quick_look__close_btn" @click="hideOverlay"></div>
                <div class="create__account__form____title">
                    Create Your Account
                </div>
                <div class="create__account__form____subheading">
                    It's quick and easy.
                    <form class="signup_form" @submit.prevent="createUser">
                        <input class='createUser____2_col createUser____ml' type='text' v-model='new_first_name' required placeholder='First name'/>
                        <input class='createUser____2_col' type='text' v-model='new_surname' required placeholder='Surname'/>
                        <input class='createUser____1_col' type='email' v-model='new_email' required placeholder='Email address'
                               @change='clearForm'
                               :class="{loginForm__error____highlight : new_form_error.code == 'invalid-email' ||
                               new_form_error.code == 'auth/email-already-in-use' ||
                               new_form_error.code == 'invalid-email-and-dob'}"/>
                        <input class='createUser____1_col' type='password' v-model='new_password' required placeholder='New Password'
                            :class="{loginForm__error____highlight : new_form_error.code == 'invalid-password'}"
                        />
                        <div class="createAccountForm__error">
                            <div v-if="new_form_error.code == 'invalid-email' || new_form_error.code == 'invalid-email-and-dob'">
                                <span class="loginForm__error____icon"></span>
                                <span class="loginForm__error____body">That email address doesn't look quite right.</span>
                            </div>
                            <div v-if="new_form_error.code == 'auth/email-already-in-use'">
                                <span class="loginForm__error____icon"></span>
                                <span class="loginForm__error____body">An account with this email already exists. Log in or reset your password.</span>
                            </div>
                            <div v-if="new_form_error.code == 'invalid-password'">
                                <span class="loginForm__error____icon"></span>
                                <span class="loginForm__error____body">Must have at least 8 characters, 1 upper, 1 lowercase and 1 number.</span>
                            </div>
                        </div>
                        <div class="createUser____subheading">
                           <div class="createAccountForm__error____title">Date of birth (Optional)</div> 
                           <div class="createAccountForm__error____inline"  
                                v-if="new_form_error.code == 'invalid-dob' || new_form_error.code == 'invalid-email-and-dob'">
                                <span class="loginForm__error____icon"></span>
                                <span>Date outside of month range.</span>
                           </div>
                        </div>

                            <select v-model="day_of_birth" name="day_of_birth" id="day_of_birth" class="createUser____ml"
                                :class="{loginForm__error____highlight : new_form_error.code == 'invalid-dob' ||
                                new_form_error.code == 'invalid-email-and-dob'}">
                                <option disabled value="">Select Day</option>
                                <option v-for="(day, index) in this.days" :key="index" :value="day">{{day}} </option>
                            </select>
                            <select v-model="month_of_birth" name="month_of_birth" id="month_of_birth" class="createUser____ml" 
                            :class="{loginForm__error____highlight : new_form_error.code == 'invalid-dob' ||
                                new_form_error.code == 'invalid-email-and-dob'}"
                        >
                                <option disabled value="">Select Month</option>
                                <option v-for="(month, index) in this.months" :key="index" :value="index">{{month}} </option>
                            </select>
                            <select v-model="year_of_birth" name="year_of_birth" id="year_of_birth">
                                <option disabled value="">Select Year</option>
                                <option v-for="(year, index) in this.generateYears" :key="index" :value="year">{{year}} </option>
                            </select>

                            <div class="createUser____subheading">
                                <div class="createAccountForm__error____title">Gender (Optional)</div> 
                            </div>
                            <div class="createUser____radio createUser____ml">
                                <label for="female">Female
                                <input id="female" type="radio" name="sex" v-model="gender" value="female">
                                <span class="checkmark"></span>
                               </label>
                            </div>
                            <div class="createUser____radio createUser____ml">
                                <label for="male">Male
                                <input id="male" type="radio" name="sex" v-model="gender" value="male">
                                <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="createUser____radio">
                                    <label for="unlisted">Unlisted
                                    <input id="unlisted" type="radio" name="sex" v-model="gender" value="unlisted">
                                    <span class="checkmark"></span>
                                    </label>
                                </div>

                            <div class="createUser____terms">
                                <!-- By clicking Create Account, you agree to our Terms and Cookie policy. -->
                            </div>

                            <input class='createUser____2_col createUser____center createUser____button button highlight' type='submit' value='Create Account' name='create-account'/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseDB from "@/services/firebase.js"
import { mapState } from 'vuex';

export default {
    data(){
      return {
          new_first_name: '',
          new_surname: '',
          new_email: '',
          new_password: '',
          error: '',
          day_of_birth: '',
          month_of_birth: '',
          year_of_birth: '',
          gender: '',
          days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
          new_form_error: {
              "code" : '',
              "message" : ''
          }
      }
    },
    name: 'OverlayCreateAccount',
    components: {
        // OverlayRecipesPanel
        },
    computed: {
        ...mapState(['overlay', 'months', 'createAccount']),
        generateYears(){
            var range = 115;
            var allYearsBack = [];
            var d = new Date();
            var n = d.getFullYear();
            for (var i = 0; i < range; i++){
                //console.log(n - i);
                allYearsBack.push(n - i)
            }

            return allYearsBack
        }
    },
    methods: {
        hideOverlay(){
            this.$store.dispatch('setOverlayToFalse')
            this.$store.dispatch('setCreateAccountToFalse')
            this.$store.dispatch('setForgottenPWToFalse')
            //clear data
            this.new_first_name = ''
            this.new_surname = ''
            this.new_email = ''
            this.new_password = ''
            this.day_of_birth = ''
            this.month_of_birth = ''
            this.year_of_birth = ''
            this.gender = ''
            this.new_form_error.code = ''
            this.new_form_error.message = ''
            this.iosSetScrollTop()
        },
        createUser(){
            const User = {
              new_first_name: this.new_first_name,
              new_surname: this.new_surname,
              new_email: this.new_email,
              new_password: this.new_password,
              date_of_birth: new Date(Number(this.year_of_birth), Number(this.month_of_birth), Number(this.day_of_birth)),
              gender: this.gender
          }

        //console.log(User.date_of_birth);
        //validate all before submission
            var emailValid = false;
            var dobValid = false;
            var passwordValid = false;
            //validate email
            if (this.validateEmail(User.new_email) == true){
                emailValid = true
            } else {
                emailValid = false;
                console.log('email is not valid');
            }


            //validate password
            if(this.validatePassword(User.new_password)){
                passwordValid = true
            } else {
                passwordValid = false
            }


            //validate dob
            if(this.validateDateOfBirth(this.day_of_birth, this.month_of_birth)){
                dobValid = true
            } else {
                dobValid = false
            }


            //set error code
            if (emailValid == true && dobValid == true){
                this.new_form_error.code = ''
            }
            if (emailValid == false && dobValid == true){
                this.new_form_error.code = 'invalid-email'
            }
            if (emailValid == true && dobValid == false){
                this.new_form_error.code = 'invalid-dob'
            }
            if (emailValid == false && dobValid == false){
                this.new_form_error.code = 'invalid-email-and-dob'
            }

            if (emailValid == true && dobValid == true){
                //once we know the things you can see are legit, check password before submitting
                if(passwordValid == true){
                    firebase.auth().createUserWithEmailAndPassword(User.new_email, User.new_password)
                    .then((userCredential) => {
                        // console.log('whats the UID');
                        //console.log(userCredential.user.uid);
                        //currentbuild number to be incremented with each release
                        var userData =  {
                                "personal" : {
                                    "firstName" : this.new_first_name,
                                    "lastName" : this.new_surname,
                                    "gender" : this.gender,
                                    "birthday" : User.date_of_birth
                                },
                                "preferences" : {
                                    "recipesToHide" : [],
                                    "userCanPreview" : false,
                                    "favourites" : []
                                },
                                "settings" : {
                                    "currentBuild" : 1
                                }
                            };
                        var userDataString = JSON.stringify(userData);
                        firebaseDB.collection("users").doc(userCredential.user.uid).set({
                            allData : userDataString
                            // calendar : "{}"
                        })
                        
                         //add saving timestamp to the users record
                        var curr = new Date;
                              let utcDate = curr.getUTCDate(); 
                              let utcMonth = curr.getUTCMonth();
                              let utcYear = curr.getUTCFullYear();
                              let utcHours = curr.getUTCHours();
                              let utcMinutes = curr.getUTCMinutes();
                              let utcSeconds = curr.getUTCSeconds();
                              let utcCreated = new Date(Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds));

                              var offset = curr.getTimezoneOffset()
                              var offsetMilliseconds;
                              switch (offset){
                                  case -60:
                                      offsetMilliseconds = 60 * 60 * 1000
                                      break;
                                  case 0:
                                      offsetMilliseconds = 0
                                      break;
                              }

                        //create users first calendar skeleton
                        firebaseDB.collection("users").doc(userCredential.user.uid).collection('calendar').doc('weeks').set({
                        weeksLoaded :  JSON.stringify({ 'all' : []}),
                        lastTimeSaved : utcCreated.getTime() + offsetMilliseconds
                        })
            
                        // // Signed in 
                        this.$store.dispatch('logUserIn', userCredential.user.uid)
                        //now push to dashboard
                        this.$store.dispatch('setOverlayToFalse')
                        this.$router.push("dashboard")
            
                        //clear data
                        this.new_first_name = ''
                        this.new_surname = ''
                        this.new_email = ''
                        this.new_password = ''
                        this.day_of_birth = ''
                        this.month_of_birth = ''
                        this.year_of_birth = ''
                        this.gender = ''
            
            
                    })
                    .catch((error) => {
                        console.log(error);
                          this.new_form_error.message = error.message;
                          this.new_form_error.code = error.code;
                        // var errorCode = error.code;
                        // var errorMessage = error.message;
                        // ..
                    });
                } else {
                    passwordValid == false;
                    this.new_form_error.code = 'invalid-password';
                    console.log('what was the password???');
                    console.log(this.new_password);
                }


            }
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
      },
      validatePassword(newPW){
          const re = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
                            //    (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
          return re.test(String(newPW));
      },
      validateDateOfBirth(date, month){
          var entered_date = Number(date);
          var entered_month = Number(month);
          //check for impossible dates
        var validateFeb = true; 
        var monthOf30 = true;

        if (entered_month == 1 && entered_date == 30 || entered_month == 1 && entered_date == 31){
            validateFeb = false;
        }

        if (entered_month == 3 && entered_date == 31 || 
            entered_month == 5 && entered_date == 31 ||
            entered_month == 8 && entered_date == 31 ||
            entered_month == 10 && entered_date == 31){
            monthOf30 = false;
        }

        if (validateFeb == false || monthOf30 == false){
            return false
        } else {
            return true
        }
      },
      clearForm(){
          this.new_form_error.code = "";
          this.new_form_error.message = "";
      },
      iosSetScrollTop(){
      //on some iOS devices, the onscreen keyboard for login page, sets the document scrollTop
      //above the viewport and its stuck there until a user tries to scroll down, breaking the UI
      // same function is in app file
      document.body.scrollTop = 0
      console.log('iosSetScrollTop triggered');
    }
    }
}
</script>

<style lang="scss">
// @import "@/scss/_variables.scss";
.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    display: none;
}
.overlay__active {
    visibility: visible;
    opacity: 1;
    display: block;
}

.overlay__background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:#000;
    opacity: 0.9;
}

.overlay__list {
    position: absolute;
    width: 600px;
    height: 100%;
    margin-left: calc(50% - 300px);
    margin-right: calc(50% - 300px);
    @media all and (max-width: 600px) {
        width: 100%;
        margin: 0;
    }
}

.quick_look__close_btn {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 30px;
    height: 30px;
    // background-image: url('../assets/img/icon_line_submenu.svg');
    background-size: contain;
}

.quick_look__close_btn:hover {
    cursor: pointer;
}

.create__account__form {
    // font-family: $font-stack;
    position: relative;
    margin-top: 0.5em;
    background-color: #fff;
    border-radius: 15px;
    padding: 0 1.5em
}

.create__account__form____title {
    padding-top: 1.5em;
    font-size: 2em;
    font-weight: 700;
}

.create__account__form____subheading {
    font-size: 1.2em;
}

.createUser____1_col, .createUser____2_col {
    padding: 0.5em 1em;
    margin-top: 1em;
    font-size: 0.9em;
    border-radius: 30px;
    border: 2px solid transparent;
    background-color: #D8D8D8;
}

.createUser____center {
    margin-left: calc(25% + 1em);
    @media all and (max-width: 600px) {
        margin-left: 1em;
    }
}

.createUser____1_col {
    width: calc(100% - 2em - 2px);
}

.createUser____2_col {
    width: calc(50% - 2.5em - 4px);
    @media all and (max-width: 600px) {
        width: calc(100% - 2em);
    }
}
.createUser____ml {
    margin-right: 1em;
}

.createUser____radio {
    position: relative;
    display: inline-block;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    font-size: 0.9em;
    border-radius: 30px;
    border: 2px solid #0C4142;
    width: calc(33% - 2.8em);
     @media all and (max-width: 550px) {
        width: 40%;
        margin-bottom: 1em;
    }
}

.createUser____terms {
    font-size: 0.7em;
    color: #5e6163
}

.createUser____subheading {
    margin: 1em 0 0.5em 0;
    font-size: 0.8em;
    font-weight: 700;
    color: #0C4142;
    line-height: 37px;
}

.createUser____subheading____copy {
    height: 44px;
    background-color: yellow;
}

.signup_form {
    position: relative;
}

.signup_form select {
    padding: 0.5em 1em;
    font-size: 0.9em;
    border-radius: 30px;
    border: 2px solid #0C4142;
}

.signup_form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-word-break: normal;
    word-break: normal;
    width: calc(33% - 0.7em);
    @media all and (max-width: 550px) {
        width: 50%;
        margin-bottom: 1em;
    }
    
}

.createAccountForm__error {
    height: 32px;
    margin-top: 5px;
    color: red;
    font-weight: 400;
}

.createAccountForm__error____title {
    height: 32px;
    display: inline-block;
}

.createAccountForm__error____inline {
    margin-left: 6px;
    height: 32px;
    display: inline-block;
     color: red;
    font-weight: 400;
}

.loginForm__error____icon {
    display: inline-block;
    height: 22px;
    width: 19px;
    margin-right: 5px;
    // background-image: url(../assets/img/icon_error_warning.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 6px;
    // background-position: center;
}

.loginForm__error____body {
    font-size: 0.8em;
}

select.loginForm__error____highlight,
input[type=text].loginForm__error____highlight,
input[type=password].loginForm__error____highlight,
input[type=email].loginForm__error____highlight {
    border: 2px solid red;

}

.createUser____button {
    background-color: #0EA302;
    color: #fff;
    margin-bottom: 1.5em;
}

input[type=radio] {
    position: absolute;
    top: 22px;
    left: 125px;
    opacity: 0;
    // display: none;

}

.checkmark {
  position: absolute;
  top: 6px;
  right: 10px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid #0C4142;
}

.createUser____radio:hover input ~ .checkmark {
  background-color: #ccc;
}

.createUser____radio input:checked ~ .checkmark {
  background-color: #0C4142; 
} 

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.createUser____radio input:checked ~ .checkmark:after {
  display: block;
}

.createUser____radio .checkmark:after {
top: 5px;
  right: 8px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select::-ms-expand {
        display: none;
}
input[type=submit]:hover {
    cursor: pointer;
}

</style>