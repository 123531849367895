<template>
    <div>
        <div class="list__top_bar">
            <div class="list__name">
                {{ type }} ({{ this.searched.length }}/{{ this.getNumberOfDetails }})
                <div class="list__button____inline mob__show" v-if="type == 'enquiries'">
                    <router-link to="/enquiry-screen" class="">
                    <action-button :title="`+ NEW`"/>
                    </router-link>
            </div>
            </div>
            <div class="list__button" v-if="type == 'enquiries'">
                    <router-link to="/enquiry-screen" class="mob__hide">
                    <action-button :title="`+ NEW`"/>
                    </router-link>
            </div>
            <div class="list__filters">
                <search-input :details="details" @setSearchTerm="setSearchTerm"/>
                <search-by-filter :options="options" @setFilterTerm="setFilterTerm"/>
            </div>
        </div>
        <div class="list__heading">
            <div class="list__heading____id">{{ getPrefix }}</div>
            <div class="list__heading____property">Property</div>
            <div class="list__heading____postcode">Postcode</div>
            <div class="list__heading____client">Client</div>
            <div class="list__heading____status">Status</div>
        </div>
    </div>
</template>

<script>
import ActionButton from "@/components/general/ActionButton.vue";
import SearchInput from "@/components/dashboard/SearchInput.vue";
import SearchByFilter from "@/components/dashboard/SearchByFilter.vue";
export default  {
    name: 'ClientListHeadings',
    props: {
        details: Array,
        type: String,
        options: Array,
        searched: Array
    },
    data(){
        return {
            title: ""
        }
    },
    components: {
        ActionButton, SearchInput, SearchByFilter
    },
    computed: {
        getPrefix(){
            var prefix;
            if (this.type == "enquiries") {
                prefix = "J#"
            }
            if (this.type == "projects"){
                prefix = "J#"
            }
            return prefix
        },
        getNumberOfDetails(){
            var allDetails;
            if(this.type == 'projects'){
                allDetails = this.details.filter(details => {
                    return (
                            details.details.project.started == true
                        )
                })
            } else {
                allDetails = this.details
            }
            return allDetails.length
        }
    },
    methods: {
        setSearchTerm(searchTerm){
            this.$emit('setSearchTerm', searchTerm)
        },
        setFilterTerm(filterTerm){
            this.$emit('setFilterTerm', filterTerm)
        }

    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/client_list.scss";
.action_button {
    margin-left: calc(12%);
    margin-top: 22px;
    @media all and (max-width: 961px) {
        margin-left: 100px;
    }
    @media all and (max-width: 750px) {
        margin-left: 0px;
    }
}

.mob__hide {
    @media all and (max-width: 750px) {
        display: none;
    }
}

.mob__show {
    display: none;
    @media all and (max-width: 750px) {
        display: inline-block;
    }

}
</style>