<template>
    <div>
        <div class="set_status__label">TEMPLATE</div>
        <form action="" class="set_status" :class="{disabled: alreadySet == true}">
            <select id="set_status__options" name="set_status__options" class="set_status__options" v-model="selected"
            @change="$emit('update-status', selected)">
                <option v-for="(option, i) in options" :key="i" :value="option">
                    {{ option }}
                </option>>
            </select>
        </form>
    </div>
</template>

<script>
export default {
    name: 'SetFeeLetterTemplate',
    props: ['options', 'enquiry', 'template_name', 'fee_letter', 'saved'],
    data(){
        return {
            selected: this.options[0]
        }
    },
    computed: {
        alreadySet(){
            if(this.saved == true){
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        setFilterTerm(){
            if(this.template_name){
                 this.selected = this.template_name
                } else {
            }
            this.$emit('update-status', [this.selected, this.alreadySet])
        }
    },
    created(){
        this.setFilterTerm()
    }
}
</script>

<style lang="scss" scoped>
// RESETS
*,
*::before,
*::after {
  box-sizing: border-box;
}
select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  outline: none;
}
select::-ms-expand {
  display: none;
}
// RESETS END

.disabled{
    .set_status__options {
        pointer-events: none;
        color: gray !important
    }
}

.disabled.set_status {
    background-image: url('@/assets/icon_locked.svg');
    }
.set_status {
    display: inline-block;
    width: 100%;
    height: 40px;
    border-radius: 50px;
    background-color: #fff;
    background-image: url('@/assets/icon_dropdown_chev.svg');
    background-repeat: no-repeat;
    background-position: 85% center;
}
.set_status__options {
    border: 0px solid transparent;
    background-color: transparent;
    font-size: 16px;
    text-transform: uppercase;
    color: #2BB179;
    text-align: center;
    height: 40px;
    font-weight: 900;
    padding-left: 20px;
    width: calc(100% - 20px);
}

.set_status__label {
    font-weight: 900;
    color: #9C9FA0;
    padding: 10px 0px;
    text-transform: uppercase;

}

</style>