// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJESkFSZs-yZCsaRO-VG7PA54VvF0sxWk",
  authDomain: "harmony-e7b70.firebaseapp.com",
  projectId: "harmony-e7b70",
  storageBucket: "harmony-e7b70.appspot.com",
  messagingSenderId: "984924023599",
  appId: "1:984924023599:web:1c7176498b9b4b0f34cfda",
  measurementId: "G-0DR81GZGQT"
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const firebaseDB = initializeApp(firebaseConfig);

export default firebaseDB.firestore()