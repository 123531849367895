<template>
    <div class="list____height list____no_overflow" :style="height">
        <div class="list__container">
            <div class="list">
                <client-list-headings :details="details" :type="type" :options="options" :searched="searchedDetails" @setSearchTerm="setSearchTerm" @setFilterTerm="setFilterTerm"/>
            </div>
            <div class="list__empty" v-if="searchedDetails.length == 0">
               No {{ type }} to show
            </div>
        </div>
        <div class="list__container">
            <div class="list____scrollable" :style="scrollheight">
                <ul class="list">
                    <client-list-rows v-for="detail in searchedDetails" :key="detail.details.id" :detail="detail.details" :type="type"/>
                </ul>
            </div>
        </div>
        <div class="list____pb"></div>
    </div>
</template>

<script>
import ClientListRows from "@/components/dashboard/ClientListRows.vue";
import ClientListHeadings from "@/components/dashboard/ClientListHeadings.vue";
import { mapState } from 'vuex';
export default  {
    name: 'ClientList',
    components: {
        ClientListRows, ClientListHeadings
    },
    data(){
        return {
            searchTerm: "",
            filterTerm: this.options[0]
        }
    },
    props: {
        details: Array,
        type: String,
        options: Array,
        height: String,
        scrollheight: String
    },
    computed: {
        searchedDetails(){
            //if type is project, filter out enquiries that dont have a project number
            var allDetails;
            if(this.type == 'projects'){
                allDetails = this.details.filter(details => {
                    return (
                            details.details.project.started == true
                        )
                })
            } else {
                allDetails = this.details;
            }

            var statusFilteredDetails = allDetails.filter(details => {
                // CHECK IF OPEN OR ALL
                if(this.filterTerm == 'open' || this.filterTerm == 'all'){

                    if(this.type == 'projects'){
                        return (
                            details
                            // details.details.project.status.toLowerCase().match(this.filterTerm.toLowerCase())
                            )
                    } else {
                        return (
                            !details.details.status.toLowerCase().match(`agreed`.toLowerCase()) &&
                            !details.details.status.toLowerCase().match(`cancelled`.toLowerCase()) &&
                            !details.details.project.started == true
                            )
                    }
                } else {
                    if(this.type == 'projects'){
                        return (
                            details.details.project.status.toLowerCase().match(this.filterTerm.toLowerCase())
                        )
                    } else {
                        return (
                            details.details.status.toLowerCase().match(this.filterTerm.toLowerCase())
                        )
                    }
                } 
            })
            // USE THE FILTER RESULTS AND MATCH AGAINST TYPED KEYWORDS
            return statusFilteredDetails.filter(details => {
                return (
                    details.details.client_details.address_line_1.toLowerCase().match(this.searchTerm.toLowerCase()) ||
                    details.details.client_details.postcode.toLowerCase().match(this.searchTerm.toLowerCase()) ||
                    details.details.client_details.name.toLowerCase().match(this.searchTerm.toLowerCase())
                )
            })
        },
        ...mapState([
          'projects'
          ])
    },
    methods: {
        setSearchTerm(typedSearchTerm){
            this.searchTerm = typedSearchTerm
        },
        setFilterTerm(selctedFilterTerm){
            this.filterTerm = selctedFilterTerm
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/client_list.scss";
.list____height {
    background-color: #EFEFEF;
    height: calc((100vh - 132px)/2);
}
</style>