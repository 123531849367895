<template>
    <div class="agreed_surveyor">
        <div class="agreed_surveyor__title">
            <toggle-on-off @buttonClicked="toggle" :toggleState="notesVisible" :option1="`yes`" :option2="`no`" :title="`AGREED SURVEYOR?`"/>
        </div>
        <input class="agreed_surveyor__input" type="text" v-model="agreedSurveyorName" v-if="!notesVisible" placeholder="Add surveyor name...">
        <div class="agreed_surveyor__button" v-if="notesVisible">
            <action-button :title="`Create Agreed Surveyor Fee Letter`"/>
        </div>
    </div>
</template>

<script>
import ToggleOnOff from "@/components/general/ToggleOnOff.vue";
import ActionButton from "@/components/general/ActionButton.vue";
export default {
    name: 'AgreedSurveyor',
    data(){
        return {
            showOption: false,
            agreedSurveyorName: ''
        }
    },
    computed: {
        notesVisible(){
            if(this.showOption){
                return true
            } else {
                return false
            }
        },
    },
    components: {
        ToggleOnOff, ActionButton
    },
    
    methods: {
        toggle(){
            if(this.showOption == true){
                this.showOption = false
            } else {
                this.showOption = true
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.agreed_surveyor {
    margin-top: 20px;
    margin-bottom: 16px;
    height: 64px;
}
.agreed_surveyor__title {
    font-weight: 900;
    color: #9C9FA0;
    padding: 10px 0px;
    text-transform: uppercase;
}

.agreed_surveyor__input {
    font-family: 'Montserrat', sans-serif;
    height: 38px;
    border-radius: 20px;
    line-height: 42px;
    width: calc(100% - 44px);
    padding: 0 22px;
    background-color: #fff;
    border: 0px solid #EFEFEF;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    // margin-top: 10px;
    margin-bottom: 1em;
}

.agreed_surveyor__button {
    margin-top: 2em;
    text-align: right;
    // padding: 1em 0;
    width: 100%;
}

.action_button {
    width: calc(100% - 44px);
    text-align: center;
}
</style>