<template>
    <div class="project_link">
        <div>
            <div class="project_link__label">Enquiry Link</div>
            <router-link :to="{name: 'enquiry screen existing', params: {enquiry_id: enquiry.enquiry_id}}" >
            <action-button :title="`Go To Enquiry`"/>
            </router-link>
        </div>
    </div>
</template>

<script>
import ActionButton from "@/components/general/ActionButton.vue";
export default {
    name: 'EnquiryLink',
    props: ['enquiry', 'status'],
    components: {
        ActionButton
    },
    computed: {
        projectStatus(){
            if(this.enquiry != null && this.enquiry != undefined){
                if(this.enquiry.status == 'agreed'){
                    return 'enabled'
                } else {
                    return 'disabled'
                }
            } else {
                return 'disabled'
            }
        },
        // buttonTitle(){
        //     if(this.enquiry != null && this.enquiry != undefined){
        //         if(this.enquiry.project.started == true){
        //             return 'Go To Enquiry Details'
        //         } else {
        //             return 'Start Project'
        //         }
        //     } else {
        //         return 'Start Project'
        //     }
        // },
        // projectStarted(){
        //     if(this.enquiry != null && this.enquiry != undefined){
        //         if(this.enquiry.project.started == true){
        //             return true
        //         } else {
        //             return false
        //         }
        //     } else {
        //         return false
        //     }
        // },
    }
}

</script>

<style lang="scss" scoped>
.project_link__label {
    font-weight: 900;
    text-transform: uppercase;
    color: #9C9FA0;
    padding-bottom: 10px;
}
.project_link {
    padding: 0px 44px 60px 44px;
}
</style>