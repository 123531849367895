<template>
    <div class="notes">
            <div class="notes__label">{{ this.title }}</div>
            <div class="notes__container">
                <div class="notes__viewer">
                    <div class="notes__single" v-for="(note, index) in this.notes" :key="index">
                        <div class="notes__single__message">{{note.message}}</div>
                        <div class="notes__single__footer">By <span class="notes__single__author">{{note.author}}</span> on <span class="notes__single__date">{{note.date}}</span></div>
                    </div>
                </div>
            </div>
            <div class="notes__prompt">
                <div class="notes__prompt__new">
                    <input class="notes__prompt__input" type="text" v-model="note" 
                    placeholder="Type here to add a note" @keyup.enter="addNote"/>
                </div>
                <div class="notes__prompt__btn" @click="addNote"></div>
            </div>
            <!-- <span>{{ rerunner }}</span> -->
    </div>
</template>

<script>
export default {
    name: 'EnquiryNotes',
    props: ['enquiry', 'title'],
    data(){
        return {
            note: "",
            notes: []
        }
    },
    computed: {
        rerunner(){
            this.getNotes()
            return null
        }
    },
    methods: {
        getNotes(){
            if(this.enquiry){
                this.notes = this.enquiry.notes;
                this.$emit('update-notes', this.notes)
            }
        },
        addNote(){
            //Check note is not empty
            if(this.note != ""){
                var record = new Date()
                var note = {
                    author: "Preston",
                    date: record.toLocaleString('en-GB', { }),
                    message: this.note
                }
                console.log(note);
                this.notes.push(note)
                this.note = ""

                this.$emit('update-notes', this.notes)

            } else {
                console.log("theres no content to add a new note");
            }
        },
        setScrollHeight(){
            var notesViewer = document.querySelectorAll('.notes__viewer')
            for (var i = 0; i < notesViewer.length; i++){
                // notesViewer[i]newScrollTop
                var newScrollTop = notesViewer[i].scrollHeight - notesViewer[i].offsetHeight
                notesViewer[i].scrollTop = newScrollTop
            }
        }
    },
    created(){
        this.getNotes()
        this.setScrollHeight()
    },
    updated(){
        this.setScrollHeight()
    }
}
</script>

<style lang="scss" scoped>
.notes {
    padding-top: 70px;
    @media all and (max-width: 1024px) {
        padding-top: 30px
    }
}
.notes__label {
    font-weight: 900;
    text-transform: uppercase;
    color: #9C9FA0;
}

.notes__container {
    width: calc(100% - 44px);
    margin: 10px 0px;
    height: 256px;
    padding: 22px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
}

*::-webkit-scrollbar {
        background-color: transparent;
        width: 15px;
        }
        *::-webkit-scrollbar-track {
        background-color: transparent;
        }
        *::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 4px solid transparent;
        background-color: rgba(0,0,0,0.2);
        background-clip: content-box;
        }

.notes__viewer {
    height: 100%;
    width: calc(100% - 20px);
    padding-right: 20px;
    overflow-y:overlay;
}

.notes__prompt {
    position: relative;
    padding-top: 10px;
    height: 42px;
    width: 100%;
    @media all and (max-width: 1024px) {
        padding-bottom: 50px
    }
}
.notes__prompt__new {
    position: relative;
    display: inline-block;
    height: 42px;
    line-height: 42px;
    width: calc(100% - 44px - 20px);
    margin-right: 22px;
}

.notes__prompt__btn {
    display: inline-block;
    background-color: #8833FF;
    width: 42px;
    line-height: 42px;
    height: 42px;
    border-radius: 50px;
    background-image: url('@/assets/icon_notes_send.svg');
    background-repeat: no-repeat;
    background-position: 11px 9px;
    background-size: 24px 24px;
    cursor: pointer;
}

.notes__prompt__btn:hover {
    background-color:#1A86EA;
}

.notes__prompt__input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    position: absolute;
    padding: 0 22px;
    font-size: 16px;
    height: 42px;
    width: calc(100% - 44px);
    color: #000;
    font-weight: 500;
    background-color: #fff;
    border-radius: 50px;
}

.notes__single {
    margin-bottom: 15px;
    font-weight: 500;
}

.notes__single__message {
    font-size: 16px;
}



.notes__single__author {
}

.notes__single__date {
}
.notes__single__footer {
    color: #A3A1A1;
    font-size: 14px;
}

input {
    border: 0px solid transparent;
    padding: 0;
}
</style>