<template>
    <li>
        <router-link :to="{name: 'enquiry screen existing', params: {enquiry_id: detail.enquiry_id}}" v-if="this.type == 'enquiries'">
        <div class="list__row">
            <div class="list__row____id">{{getPrefix}}{{detail.enquiry_id}}</div>
            <div class="list__row____property">{{detail.client_details.address_line_1}}</div>
            <div class="list__row____postcode">{{detail.client_details.postcode}}</div>
            <div class="list__row____client">{{detail.client_details.name}}</div>
            <div class="list__row____status">{{detail.status}}</div>
        </div>
        </router-link>

        <router-link :to="{name: 'project screen existing', params: {enquiry_id: detail.enquiry_id}}" v-if="this.type == 'projects'">
        <div class="list__row" v-if="this.type == 'projects'">
            <div class="list__row____id">{{getPrefix}}{{detail.enquiry_id}}</div>
            <div class="list__row____property">{{detail.client_details.address_line_1}}</div>
            <div class="list__row____postcode">{{detail.client_details.postcode}}</div>
            <div class="list__row____client">{{detail.client_details.name}}</div>
            <div class="list__row____status">{{detail.project.status}}</div>
        </div>
        </router-link>
    </li>
</template>

<script>
import { mapState } from 'vuex';
export default  {
    name: 'ClientListRows',
    props: {
        detail: Object,
        type: String
    },
    computed: {
        getPrefix(){
            var prefix;
            if (this.type == "enquiries") {
                prefix = "J-"
            }
            if (this.type == "projects"){
                prefix = "J-"
            }
            return prefix
        },
        ...mapState([
          'projects'
          ])
    },
    components: {
    },
    methods: {
    }
}
</script>

<style lang="scss">
@import "@/scss/client_list.scss";
</style>