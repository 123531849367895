<template>
    <div>
        <nav-bar/>
        <client-list :details="enquiries" :type="`enquiries`" :options="filter_enquiries" :height="overrideHeight" :scrollheight="overrideScrollHeight"/>
        <client-list :details="enquiries" :type="`projects`" :options="filter_projects" :height="overrideHeight" :scrollheight="overrideScrollHeight"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from "@/components/general/NavBar.vue";
import ClientList from "@/components/dashboard/ClientList.vue";
// import axios from 'axios'
export default {
    data() {
        return {
            filter_enquiries: ['open', 'queued', 'working'],
            filter_projects: ['all', 'stage 1', 'stage 2', 'stage 3', 'stage 4'],
            client_list_height: 'height: calc((100vh - 132px)/2)',
            client_list_scrollable_height: 'height: calc((100vh - 132px) /2 - 118px)',
            windowWidth: ''
        }
    },
    computed: {
        overrideScrollHeight(){
            if (this.windowWidth < 750){
                //  return 'height : calc(((100vh - 132px) / 2) - 168px)'
                 return 'height: 300px; min-height : calc(((100vh - 322px) / 2)'
            } else {
                 return 'height: calc((100vh - 132px) /2 - 118px)'
            }
        },
        overrideHeight(){
            if (this.windowWidth < 750){
                //  return 'height : calc(((100vh - 132px) / 2) - 168px)'
                 return 'height: auto'
            } else {
                 return 'height: calc((100vh - 132px)/2)'
            }
        },
    ...mapState([
          'enquiries', 
          'projects' 
          ])
  },                 
    components: {
        NavBar, ClientList
    },
    methods: {
        calculateWindowWidth(){
            this.windowWidth =  window.innerWidth
        }
    },
    created(){
        this.calculateWindowWidth()
        window.addEventListener("resize", this.calculateWindowWidth);
    }
}
</script>

<style lang="scss">
// .style {
//     height : calc(((100vh - 132px) / 2) - 168px)

// }
</style>