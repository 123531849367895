<template>
   <div class="service_details__radios">
        <div class="radio_toggle radio_toggle__left" @click.prevent="selectOption1">
            <input type="radio" :name="this.name" :value="option1" :id="this.option1" :checked="this.optionOneStatus"/>
            <label :for="option1" class="toggleLeft">{{ option1 }}</label>
        </div>
        <div class="radio_toggle radio_toggle__right" @click.prevent="selectOption2">
            <input type="radio" :name="this.name" :value="option2" :id="this.option2" :checked="this.optionTwoStatus"/>
            <label :for="option2" class="toggleRight">{{ option2 }}</label>
        </div>
        <span>{{ rerunner }}</span>
    </div>
</template>

<script>
export default {
    name: 'ServiceDetailsRadio',
    props: ['option1', 'option2', 'status'],
    data(){
        return {
            name: this.option1 + "_" + this.option2,
            currentStatus: "",
            optionOneStatus: '',
            optionTwoStatus: ''
        }
    },
    computed: {
        rerunner(){
            this.setOptionOneStatus()
            this.setOptionTwoStatus()
            return null
        }
    },
    methods: {
        setOptionOneStatus(){
            if(this.option1 == this.status){
                this.optionOneStatus = true
            } else {
                this.optionOneStatus = false
            }
        },
        setOptionTwoStatus(){
            if(this.option2 == this.status){
                this.optionTwoStatus = true
            } else {
                this.optionTwoStatus = false
            }
        },
        selectOption1(){
            this.currentStatus = this.option1
            this.optionOneStatus = true
            this.optionTwoStatus = false
            this.$emit('update-service-detail-option', this.option1)
        },
        selectOption2(){
            this.currentStatus = this.option2
            this.optionOneStatus = false
            this.optionTwoStatus = true
            this.$emit('update-service-detail-option', this.option2)
        }
    },
    created(){
        this.setOptionOneStatus()
        this.setOptionTwoStatus()
    }
}
</script>

<style lang="scss" scoped>

input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

input ~ label {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    // text-transform: uppercase;
    cursor: pointer;
    @media all and (max-width: 421px) {
        font-size: 0.9em;
        text-transform:capitalize;
    }
} 

input:checked ~ label {
    background-color: #1A86EA;
    color: #fff;
}

.radio_toggle {
    display: inline-block;
    width: 50%;
    height: 40px;
}

.radio_toggle__left {
    width: calc(50% - 2px);
    border-right: 2px solid #EFEFEF;
}

.toggleLeft {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.toggleRight {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.service_details__radios {
    padding-top: 10px;
}
</style>