<template>
    <div>
       <nav-bar/>
       <client-list :details="enquiries" :type="`enquiries`" :options="filter_enquiries" :height="client_list_height" :scrollheight="client_list_scrollable_height"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from "@/components/general/NavBar.vue";
import ClientList from "@/components/dashboard/ClientList.vue";
export default {
    data() {
        return {
            filter_enquiries: ['open', 'queued', 'working', 'agreed', 'cancelled'],
            client_list_height: 'height: calc(100vh - 132px) !important',
            client_list_scrollable_height: 'height: calc((100vh - 132px) - 118px)'
        }
    },
    computed: {
        getOrderedDetails(){
            var unordered = this.enquiries;
            var ordered = {};
            Object.keys(unordered).sort().forEach(key => {
                ordered[key] = unordered[key];
            })
            return ordered;
        },
    ...mapState([
          'enquiries'
          ])
    },
    components: {
        NavBar, ClientList
    },
    created(){
    }
}
</script>

<style lang="scss" scoped>
// .list____height {
//     height: calc(100vh - 132px)
// }
// .list____scrollable {
//     height: calc((100vh - 132px) - 118px)
// }
    
</style>